import { useEffect } from "react";

import { useAuth } from "hooks";

export const authBroadcastChannel = new BroadcastChannel("auth");

export const Auth = () => {
  const { logout } = useAuth();

  useEffect(() => {
    const handleBroadcastMessage = (event: MessageEvent) => {
      if (event.data.type === "LOGOUT") {
        logout();
      }
    };

    authBroadcastChannel.addEventListener("message", handleBroadcastMessage);
  }, []);

  return null;
};
