import { useMutation, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { toast } from "react-toastify";

import { AppointmentsKeys } from "modules/Appointments/queryKeys";
import { dateFormat } from "util/common";

interface DateMutationContext {
  previousDate?: Date;
}

export const useSelectedDate = () => {
  return useQueryClient().getQueryData<Date>(AppointmentsKeys.selectedDate) || new Date();
};

export const useSelectedDateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Date, Error, Date, DateMutationContext>({
    mutationFn: async newDate => Promise.resolve(newDate),
    onMutate: newDate => {
      const previousDate = queryClient.getQueryData<Date>(AppointmentsKeys.selectedDate) || new Date();
      queryClient.setQueryData(AppointmentsKeys.selectedDate, moment(newDate).startOf("day").format(dateFormat));

      return { previousDate };
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: AppointmentsKeys.list }),
    onError: (error, _, context) => {
      queryClient.setQueryData(AppointmentsKeys.selectedDate, context?.previousDate);
      toast.error(error.message);
    }
  });

  return mutation.mutate;
};
