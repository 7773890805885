import { CommunicationAgreement, Customer, CustomerCommunicationIntervention, User } from "models";
import ApiLoadable from "models/ApiLoadable";

export enum COMMUNICATION_EVENT_TYPE {
  OnlineCheckInSent = 1,
  OnlineCheckInOpened,
  OnlineCheckInAnswered,
  DiagnoseOverviewSent,
  DiagnoseOverviewOpened,
  DiagnoseOverviewAnswered,
  DiagnoseOverviewResent,
  RepairOverviewSent,
  RepairOverviewResent,
  RepairOverviewOpened
}

export class CommunicationEvent extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  type: COMMUNICATION_EVENT_TYPE = COMMUNICATION_EVENT_TYPE.OnlineCheckInSent;
  ip: string = "";
  key_locker_pin: string = "";
  user_id: number | null = null;
  customer_communication_id: number = 0;
  customer_id: number | null = null;
  customer_name: string = "";
  user?: User;
  customer?: Customer;
  dealer_location_id: number = 0;
  appointment_id: number = 0;
  mailgun_message_id?: string;
  is_parking_gate_code_sent: boolean = false;
  receivers?: CommunicationReceiver[];
  intervention_results?: CustomerCommunicationIntervention[];
  diagnose_overview_agreed_results?: DiagnoseOverviewAgreedResult[];
  diagnose_overview_declined_results?: DiagnoseOverviewDeclinedResult[];
  diagnose_overview_contact_results?: DiagnoseOverviewContactResult[];
  agreements?: CommunicationAgreement[];
}

export class DiagnoseOverviewAgreedResult extends ApiLoadable {
  id: number = 0;
  title: string = "";
  price: number = 0;
  vat: number = 0;
  communication_event_id: number = 0;
  appointment_id?: number = undefined;
}

export class DiagnoseOverviewDeclinedResult extends ApiLoadable {
  id: number = 0;
  title: string = "";
  price: number = 0;
  vat: number = 0;
  communication_event_id: number = 0;
  appointment_id?: number = undefined;
}

export class DiagnoseOverviewContactResult extends ApiLoadable {
  id: number = 0;
  title: string = "";
  price: number = 0;
  vat: number = 0;
  communication_event_id: number = 0;
  appointment_id?: number = undefined;
}

export enum COMMUNICATION_RECEIVER_STATUS {
  // PendingStatus communication is sent but not received
  PendingStatus = 1,
  // SuccessStatus communication is sent and received
  SuccessStatus,
  // FailedStatus communication is not received due to some email error
  FailedStatus
}

export class CommunicationReceiver extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  channel: number = 0;
  destination: string = "";
  reason: string = "";
  status: COMMUNICATION_RECEIVER_STATUS = COMMUNICATION_RECEIVER_STATUS.PendingStatus;
  mailgun_message_id: string = "";
  chatshipper_conversation_id: number | null = null;
  communication_event_id: number = 0;
}
