import { CHECKLIST_TYPE, Check, QuestionResult, STATUS_IDENTIFIER, SnoozeAttachment, SnoozeItem } from "models";
import { getExtensionFromURL, getNameFromURL } from "util/common";

export function getHighestId(checklistItems: QuestionResult[] | null) {
  if (!checklistItems) return 0;
  const sortedChecklist = checklistItems.slice().sort((a, b) => a.id - b.id);
  const lastItem = sortedChecklist[sortedChecklist.length - 1];
  return lastItem?.id;
}

export const isTyreChecklist = (questionItems: QuestionResult[]) => {
  return questionItems.some(obj => obj.tyre);
};

export function filterAndSortTyreChecklist(checklist: Check[] = []) {
  const tyreChecklist: Check[] = [];
  checklist.forEach(check => {
    if (check.question_items && isTyreChecklist(check.question_items)) {
      tyreChecklist.push(check);
    }
  });
  return tyreChecklist.sort((a, b) => a.created_on.localeCompare(b.created_on)) || [];
}

export function filterAndSortStandardChecklist(checklist: Check[] = []) {
  const standardChecklist: Check[] = [];
  checklist.forEach(check => {
    if (check.checklist.checklist_type !== CHECKLIST_TYPE.ExtraPartsChecklist && check.question_items && !isTyreChecklist(check.question_items)) {
      standardChecklist.push(check);
    }
  });
  return standardChecklist.sort((a: Check, b: Check) => {
    if (a.checklist.display_order === b.checklist.display_order) {
      return a.checklist.id - b.checklist.id;
    }
    return a.checklist.display_order - b.checklist.display_order;
  });
}

export const getPriceForDisplay = (price: number | null) => {
  return price ? price.toFixed(2) : "0.00";
};

export const isCarReady = (appointmentStatus: STATUS_IDENTIFIER) => {
  return (
    appointmentStatus === STATUS_IDENTIFIER.CarReadyStatus ||
    appointmentStatus === STATUS_IDENTIFIER.QualityCheckStatus ||
    appointmentStatus === STATUS_IDENTIFIER.CarOkPlusRepairOverview ||
    appointmentStatus === STATUS_IDENTIFIER.QualityCheckPlusRepairOverview
  );
};

export const getAllSnoozeAttachments = (statusHistory: SnoozeItem[]) => {
  if (!statusHistory.length) return [];

  return statusHistory.flatMap(({ note_attachments }) =>
    note_attachments ? note_attachments.map(url => ({ url, name: getNameFromURL(url), type: getExtensionFromURL(url) }) as SnoozeAttachment) : []
  );
};
