import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Icon } from "semantic-ui-react";

import { CUSTOM_CONFIRM_TYPES, CheckList, CustomConfirm, DetailsCards, TabData } from "components";
import { Appointment, CHECKLIST_TYPE, Check, QuestionResult } from "models";
import { ChecklistPDF } from "modules/AppointmentDetails/components";
import { useChecklist } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type ExtraPartsProps = {
  isExpanded: boolean;
  checklist: Check[];
  appointment: Appointment;
  tabData: TabData;
  latestChecklist: number;
};

const getCheckData = (checklist: Check[] = [], checklistType: CHECKLIST_TYPE) => {
  return checklist?.filter((item: Check) => item.checklist.checklist_type === checklistType) || [];
};

export const getQuestionResultList = (checklist: Check[] = [], checklistType: CHECKLIST_TYPE) => {
  return getCheckData(checklist, checklistType).reduce(
    (acc: QuestionResult[], curr: Check) => (curr.question_items?.length ? [...acc, ...curr.question_items] : acc),
    []
  );
};

export const ExtraParts = ({ isExpanded, appointment, checklist, tabData, latestChecklist }: ExtraPartsProps) => {
  const { deleteCheckList, canDeleteCheck } = useChecklist(appointment.id);
  const [extraPartData, setExtraPartData] = useState<Check[]>(getCheckData(checklist, CHECKLIST_TYPE.ExtraPartsChecklist));
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const t = useTranslation().t as ITranslation;

  useMemo(() => {
    setExtraPartData(getCheckData(checklist, CHECKLIST_TYPE.ExtraPartsChecklist));
  }, [checklist]);

  const toggleShowAll = () => {
    setShowAll(prev => !prev);
  };
  const isLatestCheckList = latestChecklist === extraPartData[0]?.id;

  return (
    <DetailsCards
      id={tabData.id}
      title={`${t("extra_parts").message || "Extra Parts"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
      icon="briefcase"
      isExpanded={isExpanded}
      rightContent={
        <div className="full end">
          {isLatestCheckList && canDeleteCheck && (
            <Button className="-appointment-status icon" size="small" onClick={() => setConfirmDeleteIsOpen(true)}>
              <Icon className="trash" color="green" />
            </Button>
          )}

          {extraPartData[0].checklist?.template?.total_pdf_enabled && (
            <ChecklistPDF appointment={appointment} isPreviewable={extraPartData[0].checklist.template.is_previewable} checkId={extraPartData[0].id} />
          )}

          <Button className="-appointment-status icon" size="small" onClick={toggleShowAll}>
            <Icon className="bars" color={showAll ? "green" : "grey"} />
          </Button>

          <CustomConfirm
            isOpen={confirmDeleteIsOpen}
            handleCancel={() => setConfirmDeleteIsOpen(false)}
            handleConfirm={() => deleteCheckList.mutate(latestChecklist)}
            confirmMsg={t("v8_delete_confirm_message").message || "Are you sure that you want to delete this? You can't undo this action?"}
            isLoading={deleteCheckList.isPending}
            type={CUSTOM_CONFIRM_TYPES.Warning}
          />
        </div>
      }
    >
      {extraPartData?.[0] && (
        <CheckList
          data={extraPartData[0].question_items}
          template={extraPartData[0].checklist.template}
          showAll={showAll}
          showDropdownActions={false}
          appointment={appointment}
        />
      )}
    </DetailsCards>
  );
};
