import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Label } from "semantic-ui-react";

import { CommunicationAgreement } from "models";
import { AgreementsModal } from "modules/AppointmentDetails/components/DeskCommunicationLog/components";
import { ITranslation } from "util/interfaces";

export type DeskCommAgreement = {
  name: string;
  text: string;
};

type AgreementsProps = {
  agreements: CommunicationAgreement[];
};

export const Agreements = ({ agreements }: AgreementsProps) => {
  const [selectedAgreement, setSelectedAgreement] = useState<DeskCommAgreement | null>(null);
  const t = useTranslation().t as ITranslation;

  return (
    <div className="desk-comm-section-container">
      <div className="desk-comm-section-header">{t("v8_agreements").message || "Agreements"}</div>

      <Grid>
        <Grid.Column width={16}>
          {agreements.map((item, key) => (
            <div className="agreements-row" key={key} onClick={() => setSelectedAgreement(item)}>
              <Label>
                <FontAwesomeIcon icon={faCheckSquare} />
              </Label>
              <span className="-margin-left-10">{item.name}</span>
            </div>
          ))}
        </Grid.Column>
      </Grid>

      {!!selectedAgreement && <AgreementsModal agreement={selectedAgreement} onCloseAgreementModal={() => setSelectedAgreement(null)} />}
    </div>
  );
};
