import { useState } from "react";
import { Label, SemanticCOLORS } from "semantic-ui-react";

import { PinModal, PinTypeIcon } from "components";
import "components/Pin/Pin.scss";
import { useDealersLocations } from "hooks";
import { Appointment, DealerLocation, Intervention, PIN_STATUS, PIN_TYPE, PinModel } from "models";
import { PinType } from "modules/AppointmentDetails/components/Interventions/InterventionItem";
import { useUpdateAppointment } from "modules/AppointmentDetails/hooks";

interface PinModelWithHistory extends PinModel {
  history: PinModel[];
}

type PinProps = {
  pin: Partial<PinModelWithHistory>;
  onClick?: () => void;
  pinType: Partial<PinType>;
  enableModal?: boolean;
  intervention?: Intervention;
};

export const isWarrantyPinSignatureMissing = (
  pin?: Partial<PinModelWithHistory>,
  appointment?: Appointment,
  selectedLocation?: DealerLocation,
  intervention?: Intervention
) => {
  return (
    selectedLocation?.is_warranty_pin_signature_missing_warning_enabled &&
    pin?.pin_type_id === PIN_TYPE.Warranty &&
    intervention &&
    !intervention.is_signed_by_customer &&
    (!selectedLocation?.warranty_pin_signature_brands?.length ||
      selectedLocation.warranty_pin_signature_brands.some(brand => brand.vin_prefix && appointment?.vin_nr?.startsWith(brand.vin_prefix)))
  );
};

export const isAppointmentWarrantyPinSignatureMissing = (appointment: Appointment, location?: DealerLocation) => {
  if (!location?.is_warranty_pin_signature_missing_warning_enabled || !appointment?.warranty_pin_signature_missing_count) return false;
  if (!location?.warranty_pin_signature_brands?.length) return true;
  return location?.warranty_pin_signature_brands.some(brand => brand.vin_prefix && appointment.vin_nr.startsWith(brand.vin_prefix));
};

export const getPinColor = (
  pin?: Partial<PinModelWithHistory>,
  appointment?: Appointment,
  selectedLocation?: DealerLocation,
  intervention?: Intervention
): SemanticCOLORS => {
  if (isWarrantyPinSignatureMissing(pin, appointment, selectedLocation, intervention)) return "red";

  let color = "blue";

  if (pin?.pin_type_id === PIN_TYPE.Warranty) {
    if (selectedLocation?.is_warranty_pin_support_warning_enabled && !pin.support_nr) color = "orange";
    if (selectedLocation?.is_warranty_pin_claim_warning_enabled && !pin.claim_nr) color = "orange";
  }

  if (pin?.pin_type_id === PIN_TYPE.Recall) {
    if (selectedLocation?.is_recall_pin_support_warning_enabled && !pin.support_nr) color = "orange";
    if (selectedLocation?.is_recall_pin_claim_warning_enabled && !pin.claim_nr) color = "orange";
  }

  return color as SemanticCOLORS;
};

export const useAppointmentPinColor = (appointment: Appointment): SemanticCOLORS => {
  const { selectedLocation } = useDealersLocations();
  if (!selectedLocation) return "blue";

  if (isAppointmentWarrantyPinSignatureMissing(appointment, selectedLocation)) return "red";

  if (selectedLocation.is_warranty_pin_support_warning_enabled && appointment.warranty_pin_support_nr_missing_count) return "orange";
  if (selectedLocation.is_warranty_pin_claim_warning_enabled && appointment.warranty_pin_claim_nr_missing_count) return "orange";

  if (selectedLocation.is_recall_pin_support_warning_enabled && appointment.recall_pin_support_nr_missing_count) return "orange";
  if (selectedLocation.is_recall_pin_claim_warning_enabled && appointment.recall_pin_claim_nr_missing_count) return "orange";

  return "blue";
};

export const Pin = ({ intervention, pin, onClick, pinType, enableModal }: PinProps) => {
  if (pin.pin_status_id === PIN_STATUS.Deleted) return null;

  const { appointmentData: appointment } = useUpdateAppointment(Number(pin.appointment_id));
  const { selectedLocation } = useDealersLocations();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const pinTypeID = pinType?.value || pin.pin_type_id || 0;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    onClick?.();
    if (enableModal) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Label color={getPinColor(pin, appointment, selectedLocation, intervention)} onClick={handleClick}>
        {<PinTypeIcon pin_type_id={pinTypeID} />}
      </Label>
      {enableModal && <PinModal pin={pin} isOpen={isModalOpen} onClose={handleCancel} />}
    </>
  );
};
