import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";

import { Appointment, Car } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

const useDBB = (appointment_id: number) => {
  const queryClient = useQueryClient();

  const appointmentQueryKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointment_id) }
    }
  ];

  const fetchDBB = async () => {
    const res = await ApiInstance.post("/appointments/portal_dbb", { id: appointment_id });
    return res.data;
  };

  const refreshBDD = async () => {
    const res = await ApiInstance.post("/appointments/refresh_dbb", { id: appointment_id });
    return res.data.data;
  };

  const dbbMutation = useMutation({
    mutationFn: fetchDBB
  });

  const updateCarDetails = (data: Car) => {
    const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentQueryKey);
    if (appointmentData) {
      queryClient.setQueryData(appointmentQueryKey, { ...appointmentData, car: { ...appointmentData.car, ...data } });
    }
  };

  const refreshDBBMutation = useMutation({
    mutationFn: refreshBDD,
    onSuccess: updateCarDetails,
    onError: error => {
      toast.error(error.message);
    }
  });

  useEffect(() => {
    dbbMutation.mutate();
  }, []);

  return { dbbMutation, refreshDBBMutation };
};

export default useDBB;
