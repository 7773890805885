import { Brand } from "models";
import ApiLoadable from "models/ApiLoadable";

export class CheckInCommunicationResult extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  customer_communication_id: number = 0;
  accepted: boolean = false;
  receptionist_handled: boolean = false;
  name: string = "";
  description: string = "";
  price: number = 0;
  info_url: string = "";
  visible: boolean = false;
  status: number = 0;
  reason: string = "";
  notification_email: string = "";
  checkin_communication_result: string = "";
  can_convert_to_intervention: boolean = false;
  checkin_result_id: number | null = null;
}

export class CheckInRemark extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  receptionist_handled: boolean = false;
  customer_communication_id: number = 0;
  title: string = "";
  description: string = "";
  customer_name: string = "";
  visible: boolean = false;

  private _attachments?: CheckInAttachment[] | null = null;

  get attachments(): CheckInAttachment[] {
    return this._attachments ? this._attachments : [];
  }

  set attachments(attachments: CheckInAttachment[] | null | undefined) {
    this._attachments = attachments;
  }
}

export class CheckInAttachment extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  url: string = "";
  name: string = "";
  customer_communication_id: number = 0;
  check_in_remarks_id: number | null = null;
}

export class DiagnoseOverviewAttachments extends CheckInAttachment {}

export class CheckInService extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  dealer_id: number = 0;
  name: string = "";
  description: string = "";
  price: number = 0;
  info_url: string = "";

  private _brands?: Brand[] | null = null;

  get brands(): Brand[] {
    return this._brands ? this._brands : [];
  }

  set brands(brands: Brand[] | null) {
    this._brands = brands;
  }
}

export class CommunicationAgreement extends ApiLoadable {
  id: number = 0;
  created_on: string = "";
  updated_on: string = "";
  name: string = "";
  text: string = "";
  dealer_id: number = 0;
  parent_id: number | null = null;
  deleted: boolean = false;
  optional_check_in: boolean = false;
  optional_customcom: boolean = false;
  visible_check_in: boolean = false;
  visible_customcom: boolean = false;
  accepted: boolean = false;
  communication_event_id?: number | null = null;
}

export enum CHECKIN_COMMUNICATION_RESULT_STATUS {
  NotSent = 0,
  Pending,
  Success,
  Failed
}
