import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Popup } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData } from "components";
import { Appointment, CHECKIN_COMMUNICATION_RESULT_STATUS, CheckInCommunicationResult, QuestionResult } from "models";
import "modules/AppointmentDetails/components/CustomerActions/components/CheckInOptionalService/CheckInOptionalService.scss";
import { ITranslation } from "util/interfaces";

type CheckInOptionalServiceProps = {
  title: string;
  data: CheckInCommunicationResult[];
  canCreateIntervention: boolean;
  onSelect: (data: ActionModalSelectorData) => void;
  appointment: Appointment;
  onToggleIsHandledCustomerActions: (data: CheckInCommunicationResult) => void;
};

export const CheckInOptionalService = ({ title, data, canCreateIntervention, onSelect, appointment, onToggleIsHandledCustomerActions }: CheckInOptionalServiceProps) => {
  if (!data) return null;

  const t = useTranslation().t as ITranslation;
  const communicationStatus = [CHECKIN_COMMUNICATION_RESULT_STATUS.Success, CHECKIN_COMMUNICATION_RESULT_STATUS.Failed];
  return (
    <div className="CustomerActions-items">
      {data
        .filter(item => item.visible && item.accepted)
        .sort((a, b) => a.id - b.id)
        .map(item => {
          const notificationEmailItemActive = communicationStatus.includes(item.status);
          const actionModelData = {
            title: item.name,
            raw: item.description,
            solution: "",
            price: item.price,
            appointment_id: appointment.id,
            checkin_result_id: item.id
          };

          const isConvertToInterventionButtonDisabled =
            item.can_convert_to_intervention === false || appointment?.interventions?.some(intervention => intervention.check_in_result_id === item.id);
          const successEmailMessage = `${t("v8_sent_to").message || "Sent to"} ${item.notification_email} ${t("v8_on").message || "on"} ${moment(item.updated_on).format("DD-MM-YYYY HH:mm")}`;
          const failedMailMessage = `${t("v8_failed_to_send_to").message || "Failed to send to"} ${item.notification_email} ${item.reason}`;

          return (
            <Grid key={item.id} className="actions-container">
              <Grid.Column width={12}>
                <div className={`icon-container skyblue ${!notificationEmailItemActive ? "disabled" : ""}`}>
                  <Popup
                    disabled={!item.notification_email}
                    content={
                      <div className="InfoPopup">
                        <p className="InfoTitle">{title}</p>
                        {item.status === CHECKIN_COMMUNICATION_RESULT_STATUS.Success ? (
                          <p className="InfoDescription">{`${t("v8_sent_to").message || "Sent to"} ${item.notification_email} ${t("v8_at").message || "at"} ${moment(
                            item.updated_on
                          ).format("HH:mm")} `}</p>
                        ) : (
                          <p className="InfoDescription">{item.notification_email}</p>
                        )}
                      </div>
                    }
                    trigger={<Icon className="cart" />}
                  />
                </div>
                <p className="title">{item.name}</p>
              </Grid.Column>
              <Grid.Column width={4}>
                <div className="end-grid">
                  {item.price > 0 ? <p className="title checkin-price">€ {item.price.toFixed(2)}</p> : null}
                  <div className="status-container">
                    {!item.notification_email && (
                      <div className="changeHandleStatus" onClick={() => onToggleIsHandledCustomerActions(item)}>
                        <Icon className={item.receptionist_handled ? "check circle outline green" : "circle xmark outline red"} />
                      </div>
                    )}{" "}
                    {item.notification_email && (
                      <div className={`${notificationEmailItemActive ? "notificationEmailActive" : ""}`}>
                        <Popup
                          disabled={!notificationEmailItemActive}
                          content={item.status === CHECKIN_COMMUNICATION_RESULT_STATUS.Success ? successEmailMessage : failedMailMessage}
                          trigger={
                            <div className="mailTriggerWrapper">
                              <Icon className={`envelope ${notificationEmailItemActive ? "white" : "grey"}`} size="small" />
                            </div>
                          }
                        />
                      </div>
                    )}
                    {canCreateIntervention && (
                      <ActionModalSelector
                        data={actionModelData as QuestionResult}
                        onSelect={onSelect}
                        onlyIntervention
                        isConvertToInterventionButtonDisabled={isConvertToInterventionButtonDisabled}
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          );
        })}
    </div>
  );
};
