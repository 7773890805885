import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { WebSocketComponent } from "components";
import { useWebSocketStatus } from "components/WebSocket/useWebSocketStatus";
import ENV from "config/Env";
import { useAuth, useDealersLocations, useUser } from "hooks";
import { DealerLocation } from "models";
import Service from "modules/Auth/service";
import ApiInstance from "util/Api";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths
import packageJson from "../../package.json";

export const useApp = () => {
  const { logout } = useAuth();
  const user = useUser();
  const history = useHistory();

  const { dealerMutation, selectedLocation } = useDealersLocations();
  const isConnected = useWebSocketStatus();

  const checkUser = async () => {
    if (ApiInstance.authToken) {
      const user = await Service.getUser();
      if (!user) {
        logout();
        return;
      }

      if (ApiInstance.zohoAsapJWT && (window as any).ZohoDeskAsapReady) {
        (window as any).ZohoDeskAsapReady(() => {
          (window as any).ZohoDeskAsap.invoke("login", (sucessCallback: (token: string) => void, _: () => void) => {
            sucessCallback(ApiInstance.zohoAsapJWT);
          });
        });
      }
    }
  };

  const setZohoDeskASAP = (): void => {
    (window as any).ZohoDeskAsapReady = function (s: () => void) {
      (window as any).ZohoDeskAsap__asyncalls = (window as any).ZohoDeskAsap__asyncalls || [];
      if ((window as any).ZohoDeskAsapReadyStatus) {
        if (s) (window as any).ZohoDeskAsap__asyncalls.push(s);
        (window as any).ZohoDeskAsap__asyncalls.forEach((s: () => void) => s());
        (window as any).ZohoHCAsap__asyncalls = null;
      } else if (s) (window as any).ZohoHCAsap__asyncalls.push(s);
    };

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = ENV.zohoASAP;
    script.defer = true;

    document.getElementsByTagName("head")[0].appendChild(script);
  };

  useEffect(() => {
    document.title = `Claire V${packageJson.version}`;
    setZohoDeskASAP();
    checkUser();
  }, []);

  useEffect(() => {
    ApiInstance.setHistory(history);
  }, [history]);

  useEffect(() => {
    if (user) {
      dealerMutation.mutate();
    }
  }, [user]);

  useEffect(() => {
    if (!selectedLocation || !isConnected) return;
    WebSocketComponent.subscribeToLocationQueue((selectedLocation as DealerLocation).notifier_key);

    return () => {
      if (isConnected) {
        WebSocketComponent.unsubscribeFromLocationQueue((selectedLocation as DealerLocation).notifier_key);
      }
    };
  }, [selectedLocation, isConnected]);
};
