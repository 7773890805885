import moment from "moment";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Icon, Loader } from "semantic-ui-react";

import { AppointmentPin, CUSTOM_CONFIRM_TYPES, CUSTOM_REASON, Can, CustomConfirm, TabData, Tabs } from "components";
import { useDealersLocations, useScrollPageTop } from "hooks";
import { CHECKLIST_TYPE, KeylockerCommunication, KeylockerRemark, STATUS_IDENTIFIER } from "models";
import "modules/AppointmentDetails/AppointmentDetails.scss";
import {
  ActivityLog,
  AppointmentDate,
  AppointmentNotes,
  AppointmentStatus,
  CarDetails,
  CopyContent,
  CustomerActions,
  ExtraParts,
  IndicatorsAction,
  Interventions,
  SnoozedItems,
  StandardList,
  getActivityLogData,
  getChecklistSnoozedItems,
  getQuestionResultList
} from "modules/AppointmentDetails/components";
import { useAppointmentData, useChecklist, useUpdateAppointment } from "modules/AppointmentDetails/hooks";
import { filterAndSortStandardChecklist, filterAndSortTyreChecklist, getHighestId } from "modules/AppointmentDetails/utils";
import { getTitle } from "util/common";
import { ITranslation } from "util/interfaces";

type AppointmentDetailsProps = {
  t: ITranslation;
};

const AppointmentDetails = ({ t }: AppointmentDetailsProps) => {
  useScrollPageTop();
  const { selectedLocation } = useDealersLocations();
  const { id }: { id: string } = useParams();
  const router = useHistory();
  const [tabsData, setTabsData] = useState<Map<string, TabData>>(
    new Map([
      ["details", { id: "details", icon: <Icon className="circle exclamation" />, data: [] }],
      ["customerActions", { id: "customerActions", icon: <Icon className="calendar check regular" /> }],
      ["snoozedItems", { id: "snoozedItems", icon: <Icon className="clock" /> }],
      ["notes", { data: [], id: "notes", icon: <Icon className="note sticky" /> }],
      ["interventions", { data: [], id: "interventions", icon: <Icon className="screwdriver wrench" /> }],
      ["extraParts", { id: "extraParts", icon: <Icon className="briefcase" /> }],
      ["standardList", { id: "standardList", icon: <Icon className="list" /> }],
      ["tireElement", { id: "tireElement", icon: <Icon className="tire" /> }],
      ["activityLog", { id: "activityLog", icon: <Icon className="user clock" /> }]
    ])
  );
  const [expanded, setExpanded] = useState(true);
  const [showVat, setShowVat] = useState(false);
  const [showCancelModel, setShowCancelModel] = useState(false);
  const [cancelReason, setCancelReason] = useState<number | undefined>(undefined);
  const [otherCustomReason, setOtherCustomReason] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { data: appointment, updateStatus, cancelReasonsQuery, cancelAppointment } = useAppointmentData(id);
  const { appointmentUpdateDatesMutation } = useUpdateAppointment(Number(id));

  const {
    checklistQuery: { data: checklist }
  } = useChecklist(Number(id), true);

  const toggleExpand = () => {
    setExpanded(prev => !prev);
  };

  const handleCancelAppointment = () => {
    setShowCancelModel(true);
  };

  const onRequestCloseModal = () => {
    setShowCancelModel(false);
  };

  const handleCancelConfirm = () => {
    if (typeof cancelReason !== "undefined") {
      cancelAppointment.mutate(cancelReason == CUSTOM_REASON ? { custom_reason: otherCustomReason } : { reason_id: cancelReason });
      setShowCancelModel(false);
    }
  };

  const getTotalNotesData = () => {
    if (appointment) {
      const { notes = [] } = appointment;
      const dmsNotes = appointment.dms_notes || [];
      const dmsCarNotes = appointment.car?.dms_notes || [];
      return [...(notes ?? []), ...dmsNotes, ...dmsCarNotes];
    }
    return [];
  };

  const getCustomerActionsData = () => {
    const keylockerRemarks =
      appointment?.keylocker_communications
        ?.filter((comm: KeylockerCommunication) => comm.remark)
        ?.map((communication: KeylockerCommunication) => {
          return communication.remark as KeylockerRemark;
        }) || [];
    return [
      ...(appointment?.customer_communication?.check_in_results || []),
      ...(appointment?.customer_communication?.check_in_remarks || []),
      ...(appointment?.customer_communication?.diagnose_overview_remarks || []),
      ...keylockerRemarks
    ];
  };

  useEffect(() => {
    if (appointment || checklist) {
      setTabsData((prev: Map<string, TabData>) => {
        const tabs = new Map<string, TabData>([...prev]);
        const customerActions = getCustomerActionsData();
        const snoozedItems = getChecklistSnoozedItems(checklist);
        const notes = getTotalNotesData();
        const standardList = filterAndSortStandardChecklist(checklist);
        const extraParts = getQuestionResultList(checklist, CHECKLIST_TYPE.ExtraPartsChecklist);
        const tireElement = filterAndSortTyreChecklist(checklist);
        const activityLog = getActivityLogData(appointment, t);

        if (appointment?.interventions?.length !== undefined) {
          const interventionTab = tabs.get("interventions");
          if (interventionTab) {
            interventionTab.data = appointment.interventions.length ? appointment.interventions : [];
          }
        }
        if (notes.length !== undefined) {
          const noteTab = tabs.get("notes");
          if (noteTab) {
            noteTab.data = notes.length ? notes : [];
          }
        }
        if (standardList.length !== undefined) {
          const standardTab = tabs.get("standardList");
          if (standardTab) {
            standardTab.data = standardList.length ? standardList : null;
          }
        }
        if (extraParts.length !== undefined) {
          const extraPartsTab = tabs.get("extraParts");
          if (extraPartsTab) {
            extraPartsTab.data = extraParts.length ? extraParts : null;
          }
        }
        if (snoozedItems.length !== undefined && selectedLocation?.schedule_enabled) {
          const snoozedItemsTab = tabs.get("snoozedItems");
          if (snoozedItemsTab) {
            snoozedItemsTab.data = snoozedItems.length ? snoozedItems : null;
          }
        }
        if (tireElement.length !== undefined) {
          const tireElementTab = tabs.get("tireElement");
          if (tireElementTab) {
            tireElementTab.data = tireElement.length ? tireElement : null;
          }
        }
        if (activityLog.length !== undefined) {
          const activityLogTab = tabs.get("activityLog");
          if (activityLogTab) {
            activityLogTab.data = activityLog.length ? activityLog : null;
          }
        }
        if (customerActions.length !== undefined) {
          const customerActionsTab = tabs.get("customerActions");
          if (customerActionsTab) {
            customerActionsTab.data = customerActions.length ? customerActions : null;
          }
        }

        return tabs;
      });
    }
  }, [appointment, checklist, selectedLocation?.schedule_enabled]);

  const navigateBack = () => {
    if (hasChanges) {
      setShowConfirmModal(true);
    } else {
      router.goBack();
    }
  };

  const handleConfirm = () => {
    setShowConfirmModal(false);
    setHasChanges(false);
    router.goBack();
  };

  const onRequestCloseHasChangeModal = () => {
    setShowConfirmModal(false);
  };

  const toggleShowVat = () => {
    setShowVat(prev => !prev);
  };

  const handleChangeAppointmentDate = (date: Date) => {
    appointmentUpdateDatesMutation.mutate({ time_car_app: moment(date).hour(12).format() });
  };

  const latestChecklist = getHighestId(checklist);
  return (
    <div className={`AppointmentDetails ${appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CanceledStatus ? "CanceledAppointment" : ""}`}>
      {appointment ? (
        <div className="AppointmentDetails-container">
          <div className="AppointmentDetails-NavigationHeader">
            <div className="AppointmentDetails-NavigationHeader-container">
              <div className="row">
                <Can I={["new-status", "restore"]} the="appointments">
                  <AppointmentStatus appointment={appointment} onClick={updateStatus.mutate} isUpdating={updateStatus.isPending} />
                </Can>
                {appointment.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && (
                  <IndicatorsAction data={appointment} showVat={showVat} onSwitchShowVat={toggleShowVat} />
                )}
              </div>
              <div className="row">
                <Tabs data={tabsData} offset={-190} hideBorder />
                <div className="sub-row">
                  <div className="Appointment-time">
                    <p>
                      <strong>{t("v8_appointment").message || "Appointment"}</strong>
                      <AppointmentDate appointment={appointment} onChangeDate={handleChangeAppointmentDate} />
                    </p>

                    <p>
                      <strong>{t("v8_in").message || "In"}</strong>
                      {appointment?.due_in && moment(appointment.due_in).format("DD-MM-YYYY HH:mm")}
                    </p>

                    <p>
                      <strong>{t("v8_out").message || "Out"}</strong>
                      {appointment?.car_return_time && moment(appointment.car_return_time).format("DD-MM-YYYY HH:mm")}
                    </p>
                  </div>

                  <div className="Appointment-time">
                    <CopyContent content={appointment?.wo_nr ?? ""}>
                      <p>
                        <strong>{t("v8_wo").message || "WO"} #</strong>
                        <span className={appointment?.internal ? "info-wo" : ""}>{appointment?.wo_nr}</span>
                        <span className="appointment-pin ml-5">
                          <AppointmentPin appointment={appointment} />
                        </span>
                      </p>
                    </CopyContent>
                  </div>
                  <Button className="-appointment-status icon" size="small" onClick={toggleExpand}>
                    <Icon className={`arrows ${expanded ? "minimize" : "maximize"}`} color="green" />
                  </Button>
                  {localStorage.getItem("logged_in_with_qr_code") !== "true" && (
                    <Button className="-appointment-status" size="small" active onClick={navigateBack}>
                      <Icon className="angle left" />
                      {t("v8_back").message || "Back"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="AppointmentDetails-cards mt-20">
            <CarDetails
              tabData={tabsData.get("details") as TabData}
              title={t("v8_appointment_details").message || "Appointment Details"}
              data={appointment}
              isExpanded={expanded}
              handleCancelAppointment={handleCancelAppointment}
            />
          </div>

          <div className="AppointmentDetails-cards mt-25">
            {tabsData.get("customerActions")?.data && (
              <CustomerActions tabData={tabsData.get("customerActions") as TabData} isExpanded={expanded} appointment={appointment} />
            )}

            {tabsData.get("snoozedItems")?.data && <SnoozedItems tabData={tabsData.get("snoozedItems") as TabData} isExpanded={expanded} appointment={appointment} />}
            {tabsData.get("notes")?.data && <AppointmentNotes tabData={tabsData.get("notes") as TabData} appointment={appointment} isExpanded={expanded} />}
            {tabsData.get("interventions")?.data && (
              <Interventions
                tabData={tabsData.get("interventions") as TabData}
                isExpanded={expanded}
                appointment={appointment}
                showVat={showVat}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
              />
            )}
            {tabsData.get("extraParts")?.data && (
              <ExtraParts
                latestChecklist={latestChecklist}
                tabData={tabsData.get("extraParts") as TabData}
                isExpanded={expanded}
                checklist={checklist}
                appointment={appointment}
              />
            )}
            {tabsData.get("standardList")?.data &&
              filterAndSortStandardChecklist(checklist).map((item, index) => {
                if (item.question_items) {
                  return (
                    <StandardList
                      id={index === 0 ? tabsData.get("standardList")?.id : undefined}
                      key={item.id}
                      latestChecklist={latestChecklist}
                      isExpanded={expanded}
                      data={item}
                      title={getTitle({
                        title: item.checklist.name,
                        data: item.question_items
                      })}
                      appointment={appointment}
                    />
                  );
                }
                return null;
              })}

            {tabsData.get("tireElement")?.data &&
              filterAndSortTyreChecklist(checklist).map((item, index) => {
                if (item.question_items) {
                  return (
                    <StandardList
                      icon="tire"
                      latestChecklist={latestChecklist}
                      id={index === 0 ? tabsData.get("tireElement")?.id : undefined}
                      key={item.id}
                      title={getTitle({
                        title: item.checklist.name,
                        data: item.question_items
                      })}
                      isExpanded={expanded}
                      data={item}
                      appointment={appointment}
                    />
                  );
                }
                return null;
              })}
          </div>

          {tabsData.get("activityLog")?.data && (
            <div className="AppointmentDetails-cards mt-25">
              <ActivityLog tabData={tabsData.get("activityLog") as TabData} isExpanded={expanded} appointment={appointment} />
            </div>
          )}

          <CustomConfirm
            type={CUSTOM_CONFIRM_TYPES.Danger}
            confirmReasons={cancelReasonsQuery.data}
            allowCustomReason
            isOpen={showCancelModel}
            customClass={"CancelAppointmentModal"}
            handleCancel={onRequestCloseModal}
            handleConfirm={handleCancelConfirm}
            handleReasonChange={value => setCancelReason(value)}
            handleChangeCustomReason={e => setOtherCustomReason(e.target.value)}
            isLoading={cancelReasonsQuery.isLoading}
            confirmMsg={t("v8_confirm_cancel_appointment_message").message || "Are you sure that you want to cancel this appointment?"}
            error={cancelReasonsQuery.error ? cancelReasonsQuery.error.message : ""}
            cancelButtonText={t("v8_close").message || "Close"}
            confirmButtonText={t("v8_cancel").message || "Cancel"}
            placeholderText={t("v8_cancellation_reason").message || "Cancellation reason"}
          />
          <CustomConfirm
            isLoading={false}
            type={CUSTOM_CONFIRM_TYPES.Warning}
            isOpen={showConfirmModal}
            handleConfirm={handleConfirm}
            handleCancel={onRequestCloseHasChangeModal}
            confirmMsg={t("v8_unsaved_changes_confirm_message").message || "You have some unsaved changes. If you proceed, these changes will be lost."}
          />
        </div>
      ) : (
        <div className="AppointmentDetails-loader">
          <Loader active inline />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(AppointmentDetails);
