import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";

import { AppointmentNotes, AppointmentPin, AppointmentStatusCell, FilterIcons, getAppointmentStatus, isAppointmentWarrantyPinSignatureMissing } from "components";
import { useDealersLocations, useUser } from "hooks";
import { Appointment, Checklist } from "models";
import { WO_FILTERS } from "modules/Appointments";
import { SpecialIndicators } from "modules/Appointments/components";
import "modules/Appointments/components/AppointmentTable/AppointmentTable.scss";
import {
  AppointmentTableDateColumnCell,
  ColumnDataSelector,
  ColumnDataSelectorChildMethods,
  appointmentHeaderDropdownOptions
} from "modules/Appointments/components/AppointmentTable/components";
import { useGetSpecialIndicatorsInitialState } from "modules/Appointments/hooks/useGetSpecialIndicatorsInitialState";
import { getCustomerName, getPreference, getUserNameInitials, setPreference } from "util/common";
import { ITranslation } from "util/interfaces";
import { queryKeys } from "util/keyFactory";

const columnHelper = createColumnHelper<Appointment>();

interface AppointmentsTableProps {
  appointments: Appointment[];
  isTodaysList: boolean;
  woColumnAccessor: `${WO_FILTERS}`;
  handleChangeWoColumnAccessor: (value: string) => void;
}

const ACCESSOR_KEYS = {
  DateColumn: "date-column-accessor-value",
  CustomerColumn: "customer-column-accessor-value",
  MakeColumn: "make-column-accessor-value",
  LastUserColumn: "last-user-column-accessor-value"
};

export const AppointmentTable = ({ appointments, isTodaysList, handleChangeWoColumnAccessor, woColumnAccessor }: AppointmentsTableProps) => {
  const { selectedLocation } = useDealersLocations();
  const user = useUser();
  const t = useTranslation().t as ITranslation;
  const { data: carMakeValues } = useQuery({ queryKey: [queryKeys.appointments.carMakes] });

  const [dateColumnAccessor, setDateColumnAccessor] = useState(getPreference(ACCESSOR_KEYS.DateColumn, "time_car_app"));
  const [customerColumnAccessor, setCustomerColumnAccessor] = useState(
    getPreference(ACCESSOR_KEYS.CustomerColumn, appointmentHeaderDropdownOptions(t, carMakeValues as string[]).customer[0].key)
  );
  const [lastUserColumnAccessor, setLastUserColumnAccessor] = useState(
    getPreference(ACCESSOR_KEYS.LastUserColumn, appointmentHeaderDropdownOptions(t, carMakeValues as string[]).lastUser?.[0]?.key || [])
  );
  const queryClient = useQueryClient();
  const [selectedCarMake, setSelectedCarMake] = useState<string[]>(getPreference(ACCESSOR_KEYS.MakeColumn, []));
  const { indicatorFilters, handleIndicatorFilters } = useGetSpecialIndicatorsInitialState();

  useEffect(() => {
    if (user) {
      if (!dateColumnAccessor) setDateColumnAccessor(user.list_appointments_by_scheduled_range ? "car_return_time" : "time_car_app");
    }
  }, [user]);

  useEffect(() => {
    queryClient.setQueryData([queryKeys.appointments.carMakeSearch], selectedCarMake);
  }, [selectedCarMake]);

  const getHiddenColumns = () => {
    const hiddenColumns = {} as any;

    if (!selectedLocation?.apk_visible) hiddenColumns.apk = false;

    return hiddenColumns;
  };

  const handleMultipleCarMakeFilter = (value: string) => {
    if (value === "All") {
      setPreference(ACCESSOR_KEYS.MakeColumn, []);
      return setSelectedCarMake([]);
    }
    setSelectedCarMake(prev => {
      if (!value) return [];
      const index = prev.indexOf(value);
      const newValue = index === -1 ? [...prev, value] : prev.filter(item => item !== value);
      setPreference(ACCESSOR_KEYS.MakeColumn, newValue);
      return newValue;
    });
  };

  const getUser = (appointment: Appointment) => {
    if (lastUserColumnAccessor === "customer_owner") {
      const { customer_owner_name, car_in_shop_set_by, customer_owner_profile_picture } = appointment;
      const carInShopSetByName = car_in_shop_set_by ? `${car_in_shop_set_by.first_name || ""} ${car_in_shop_set_by.last_name || ""}`.trim() : "";
      const name = customer_owner_name || carInShopSetByName;
      const profilePicture = !customer_owner_name ? car_in_shop_set_by?.profile_picture : customer_owner_profile_picture;

      return { name, profilePicture, lastUpdatedAt: null };
    }

    if (lastUserColumnAccessor === "receptionist_changed") {
      return {
        name: appointment.last_receptionist_name,
        profilePicture: appointment.last_receptionist_profile_picture,
        lastUpdatedAt: null
      };
    }

    return {
      name: appointment.last_user,
      profilePicture: appointment.last_user_profile_picture,
      lastUpdatedAt: appointment.last_user_at
    };
  };

  const handleChangeDateColumnAccessor = (value: string) => {
    setPreference(ACCESSOR_KEYS.DateColumn, value);
    setDateColumnAccessor(value);
  };

  const handleChangeCustomerColumnAccessor = (value: string) => {
    setPreference(ACCESSOR_KEYS.CustomerColumn, value);
    setCustomerColumnAccessor(value);
  };

  const handleChangeLastUserColumnAccessor = (value: string) => {
    setPreference(ACCESSOR_KEYS.LastUserColumn, value);
    setLastUserColumnAccessor(value);
  };

  const columns = [
    columnHelper.accessor((row: Appointment) => row.wo_nr, {
      id: "wo",
      header: () => (
        <div className="appointment-table-wrapper pointer">
          <p>
            {`${t("v8_wo_nr").message} #` || "WO#"} {appointments?.length}
          </p>
          <ColumnDataSelector selectedColumn={woColumnAccessor} column="wo" setSelectedColumn={handleChangeWoColumnAccessor} />
        </div>
      ),
      size: 105,
      cell: info => {
        return (
          <p className="ellipsis ellipsis-wo-nr">
            <AppointmentPin appointment={info.row.original} />

            <Popup basic content={info.renderValue()} trigger={<span className={info.row.original.internal ? "internal-wo" : ""}>{info.renderValue()}</span>} />
          </p>
        );
      }
    }),

    columnHelper.accessor(
      row => {
        dateColumnAccessor === "time_car_app" ? row.time_car_app : row.car_return_time;
        const key = dateColumnAccessor as keyof typeof row;
        const date = row[key] ? String(row[key]) : null;
        return { date, isPinned: row.is_pinned };
      },
      {
        id: "date",
        cell: info => <AppointmentTableDateColumnCell dateColumnAccessor={dateColumnAccessor} value={info.getValue()} isTodaysList={isTodaysList} />,
        header: () => {
          return (
            <div className="appointment-table-wrapper pointer">
              <p>{dateColumnAccessor === "time_car_app" ? t("v8_appointment_date").message || "Appointment Date" : t("v8_scheduled_out").message || "Scheduled Out"}</p>
              <ColumnDataSelector selectedColumn={dateColumnAccessor} column="date" setSelectedColumn={handleChangeDateColumnAccessor} />
            </div>
          );
        },
        size: 140
      }
    ),
    columnHelper.accessor(row => row, {
      id: "reg_nr",
      header: () => `${t("v8_reg").message} #` || "Reg #",
      size: 100,
      cell: info => {
        const appointment = info.renderValue();
        if (!appointment) return null;

        return (
          <Popup
            basic
            content={appointment.reg_number}
            trigger={
              <p className="ellipsis">
                <Link className="AppointmentTable-link" to={`/cardetails/${appointment.car_id}`}>
                  {appointment.is_lease_company ? <Icon name="building" /> : <Icon name="info circle" />}
                  {appointment.reg_number}
                </Link>
              </p>
            }
          />
        );
      }
    }),
    columnHelper.accessor(row => row.car_apk_date, {
      id: "apk",
      header: () => "APK",
      size: 100,
      cell: info => (info.renderValue() ? moment(info.renderValue()).format("DD-MM-YY") : "")
    }),
    columnHelper.accessor(row => row, {
      id: "customer_name",
      header: () => {
        return (
          <div className="appointment-table-wrapper pointer">
            <p>{t(customerColumnAccessor).message || `${customerColumnAccessor[0].toUpperCase()}${customerColumnAccessor.slice(1, customerColumnAccessor.length)}`}</p>
            <ColumnDataSelector selectedColumn={customerColumnAccessor} column="customer" setSelectedColumn={handleChangeCustomerColumnAccessor} />
          </div>
        );
      },
      size: 100,
      cell: info => {
        const appointment = info.getValue();
        return (
          <p
            className={`ellipsis small ${isAppointmentWarrantyPinSignatureMissing(appointment, selectedLocation) ? "-warranty-signature-missing" : appointment.check_in_com_answered ? "-customer-sign-wo" : ""}`}
          >
            {getCustomerName(customerColumnAccessor, appointment)}
          </p>
        );
      }
    }),
    columnHelper.accessor(row => row.car_make, {
      id: "make",
      header: () => {
        return (
          <div className="appointment-table-wrapper pointer">
            <p>{t("v8_make").message || "Make"}</p>
            <ColumnDataSelector selectedColumn={selectedCarMake} multiple column="carMake" setSelectedColumn={handleMultipleCarMakeFilter} />
          </div>
        );
      },
      size: 80,
      cell: info => {
        const initial = info.renderValue();
        return <p className="ellipsis small">{initial}</p>;
      }
    }),
    columnHelper.accessor(row => row.car_model, {
      id: "model",
      header: () => t("v8_model").message || "Model",
      cell: info => {
        const initial = info.renderValue();
        return <Popup hoverable content={initial} trigger={<p className="ellipsis ellipsis-model">{initial}</p>} />;
      },
      size: 230
    }),
    columnHelper.accessor(row => row, {
      id: "note",
      header: () => t("v8_note").message || "Note",
      cell: info => {
        const initial = info.renderValue();
        if (!initial) return null;
        return <AppointmentNotes appointment={initial} />;
      },
      size: 60
    }),

    columnHelper.accessor(row => row, {
      id: "circle",
      size: 160,
      header: () => user && <SpecialIndicators data={indicatorFilters} onSelect={handleIndicatorFilters} />,
      cell: info => {
        const filterValues = info.renderValue();
        if (!filterValues) return null;
        return selectedLocation ? <FilterIcons appointment={filterValues} /> : null;
      }
    }),

    columnHelper.accessor(row => row, {
      id: "last_timestamp",
      header: () => t("v8_updated").message || "Updated",
      size: 60,
      cell: info => {
        const updatedTime = info.renderValue()?.last_timestamp;
        return updatedTime && (moment.isMoment(updatedTime) || !updatedTime.startsWith("0001-01-01T00:00:00")) ? moment(updatedTime).format("HH:mm") : "";
      }
    }),
    // columnHelper.accessor(row => row, {
    //   id: "mechanic",
    //   header: () => {
    //     return (
    //       <div className="appointment-table-user">
    //         <p className="text">M</p>
    //       </div>
    //     );
    //   },
    //   size: 30,
    //   cell: info => {
    //     const initial = info.getValue();
    //     if (!initial) return null;

    //     return (
    //       <Popup
    //         content={
    //           <div>
    //             {initial.planning_work_start && (
    //               <div>
    //                 <strong>{t("planning_work_start").message || "Planning work start"}</strong>: {moment(initial.planning_work_start).format("DD-MM-YYYY HH:mm")}
    //               </div>
    //             )}
    //             {initial.planning_work_stop && (
    //               <div>
    //                 <strong>{t("planning_work_stop").message || "Planning work stop"}</strong>: {moment(initial.planning_work_stop).format("DD-MM-YYYY HH:mm")}
    //               </div>
    //             )}
    //           </div>
    //         }
    //         disabled={!initial.planning_work_stop && !initial.planning_work_start}
    //         trigger={
    //           initial.planning_mechanic ? (
    //             <div className="appointment-table-user-container">
    //               <div className="appointment-table-user">
    //                 <p className={`text ${initial.has_panic ? "-panic-user" : ""}`}>{`${initial.planning_mechanic.first_name} ${initial.planning_mechanic.last_name}`}</p>
    //               </div>
    //             </div>
    //           ) : null
    //         }
    //       />
    //     );
    //   },
    // }),

    columnHelper.accessor(row => row, {
      id: "last_user",
      header: () => {
        const columnDataRef = useRef<ColumnDataSelectorChildMethods>(null);
        return (
          <div
            className="appointment-table-user-container"
            onClick={() => {
              columnDataRef.current?.toggleDropdown?.();
            }}
          >
            <div className="appointment-table-user appointemnt-table-user-head">
              <p className="text">{getUserNameInitials(lastUserColumnAccessor)}</p>
            </div>
            <ColumnDataSelector ref={columnDataRef} selectedColumn={lastUserColumnAccessor} column="lastUser" setSelectedColumn={handleChangeLastUserColumnAccessor} />
          </div>
        );
      },
      size: 50,
      cell: info => {
        const appointment = info.getValue();
        const value = getUser(appointment);
        const status = getAppointmentStatus(appointment.appointment_status_identifier, selectedLocation?.statuses);

        if (!value.name) return null;
        return (
          <Popup
            flowing
            content={
              <div className="appointment-table-user-popup">
                {" "}
                <div className="appointment-table-user-popup-top">
                  {value.profilePicture ? (
                    <img src={value.profilePicture} className="appointment-table-user-popup-top-initials-image" />
                  ) : (
                    <div className="appointment-table-user-popup-top-initials">{getUserNameInitials(value.name)}</div>
                  )}
                  <div className="appointment-table-user-popup-top-name">{value.name}</div>
                </div>
                {value.lastUpdatedAt && (
                  <div className="appointment-table-user-popup-bottom">
                    {value.lastUpdatedAt && <div className="appointment-table-user-popup-bottom-timestamp">{moment(value.lastUpdatedAt).format("YYYY-MM-DD hh:mm")}</div>}
                    <div className="appointment-table-user-popup-bottom-status">{status.name}</div>
                  </div>
                )}
              </div>
            }
            trigger={
              <div className="appointment-table-user appointment-table-last-user">
                {value.profilePicture ? <img src={value.profilePicture} className="image" /> : <p className="text">{getUserNameInitials(value.name)}</p>}
              </div>
            }
          />
        );
      }
    }),
    columnHelper.accessor(row => row.checklists, {
      id: "check_list",
      header: () => t("v8_checklists").message || "Checklists",
      cell: info => {
        const checks = info.getValue();
        return (
          <div className="appointment-table-wrapper">
            {checks?.map((value: Checklist, index: number) => {
              if (index < 4)
                return (
                  <Popup
                    key={`${value?.name}_${index}`}
                    content={value?.name}
                    trigger={
                      <div key={`checklist-${index}`} className="appointment-table-checklist">
                        {value?.name?.substring(0, 1)}
                      </div>
                    }
                  />
                );
              if (index === 4)
                return (
                  <div key={`checklist-${index}`}>
                    <p>...</p>
                  </div>
                );
              return null;
            })}
          </div>
        );
      },
      size: 90
    }),
    columnHelper.accessor(
      row => ({
        id: row.appointment_status_identifier,
        customcom_status: row.customer_communication?.status,
        check_paused_at: row.check_paused_at,
        car_out_of_shop: row.car_out_of_shop,
        car_in_shop: row.car_in_shop
      }),
      {
        id: "status",
        header: () => t("v8_status").message || "Status",
        size: 55,
        cell: info => <AppointmentStatusCell shadow={false} value={info.getValue()} />
      }
    )
  ];

  const table = useReactTable({
    data: appointments,
    columns,
    enableHiding: true,
    initialState: {
      columnVisibility: getHiddenColumns()
    },
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: { apk: !!selectedLocation?.apk_visible, date: selectedLocation?.date_visible as boolean }
    }
  });

  return (
    <table className="ReactTable AppointmentsTable">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th style={{ width: header.column.columnDef.size }} key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.length > 0 ? (
          table.getRowModel().rows.map(row => (
            <Link key={row.id} to={`/appointments/${row.original.id}`}>
              <tr className={row.original.has_panic ? "panicRow" : ""}>
                {row.getVisibleCells().map(cell => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            </Link>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length} className="text-center">
              {t("v8_no_records_found").message || "No Records found"}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
