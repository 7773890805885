import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionModalSelectorData, DetailsCards, ExtendedInterventionData, MODAL_TYPE, QuestionResultData, TabData, useCan } from "components";
import InterventionModal from "components/InterventionModal";
import { Appointment, CheckInCommunicationResult, CheckInRemark, DiagnoseOverviewRemark, KeylockerCommunication, KeylockerRemark } from "models";
import { CCRemarks, CheckInOptionalService } from "modules/AppointmentDetails/components/CustomerActions/components";
import { useCustomerActions } from "modules/AppointmentDetails/components/CustomerActions/components/hooks/useCustomerActions";
import "modules/AppointmentDetails/components/CustomerActions/CustomerActions.scss";
import { InterventionData } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type CustomerActionsProps = {
  isExpanded: boolean;
  appointment: Appointment;
  tabData: TabData;
};

export const CustomerActions = ({ isExpanded, appointment, tabData }: CustomerActionsProps) => {
  const [showInterventionModal, setShowInterventionModal] = useState(false);
  const [modalData, setModalData] = useState<InterventionData | QuestionResultData | null>(null);
  const { updateCheckInRemark, updateCheckInResult, updateDiagnoseOverviewRemark, updateKeylockerRemarks } = useCustomerActions(appointment.id);

  const t = useTranslation().t as ITranslation;
  const canCreateIntervention = useCan("add", "interventions");

  const keylockerRemarks =
    appointment?.keylocker_communications
      ?.filter((comm: KeylockerCommunication) => comm.remark)
      ?.map((communication: KeylockerCommunication) => {
        return communication.remark as KeylockerRemark;
      }) || [];

  const onRequestCloseModal = () => {
    setShowInterventionModal(false);
    setModalData(null);
  };

  const handleSelect = (data: ActionModalSelectorData) => {
    if (data.modalType === MODAL_TYPE.Intervention) {
      setModalData(data as ExtendedInterventionData);
      setShowInterventionModal(true);
    }
  };

  const onToggleIsHandledCustomerActions = (item: CheckInRemark | CheckInCommunicationResult | DiagnoseOverviewRemark | KeylockerRemark) => {
    if ("keylocker_communication_id" in item) updateKeylockerRemarks({ keylocker_remark_id: item.id, handled_remark: !item.receptionist_handled });

    if (appointment.customer_communication?.check_in_results?.some(e => e.id === item.id))
      updateCheckInResult({ checkin_result_id: item.id, handled: !item.receptionist_handled });

    if (appointment.customer_communication?.diagnose_overview_remarks?.some(remark => remark.id === item.id))
      updateDiagnoseOverviewRemark({
        diagnose_overview_remark_id: item.id,
        handled_remark: !item.receptionist_handled
      });

    if (appointment.customer_communication?.check_in_remarks?.some(remark => remark.id === item.id))
      updateCheckInRemark({ checkin_remark_id: (item as CheckInRemark).id, handled: !(item as CheckInRemark).receptionist_handled });
  };

  return (
    <>
      <DetailsCards
        id={tabData.id}
        title={`${t("v8_customer_actions").message || "Customer Actions"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
        icon="calendar check regular"
        isExpanded={isExpanded}
      >
        <div className="CustomerActions">
          {appointment.customer_communication?.check_in_results && (
            <CheckInOptionalService
              onToggleIsHandledCustomerActions={onToggleIsHandledCustomerActions}
              appointment={appointment}
              title={t("v8_check_in_results").message || "Check-in results"}
              data={appointment.customer_communication.check_in_results}
              onSelect={handleSelect}
              canCreateIntervention={canCreateIntervention}
            />
          )}
          <div className="CCRemarks">
            {appointment.customer_communication?.check_in_remarks && (
              <CCRemarks
                onToggleIsHandledCustomerActions={onToggleIsHandledCustomerActions}
                appointment={appointment}
                title={t("v8_check_in_remarks").message || "Check-in remarks"}
                data={appointment.customer_communication.check_in_remarks}
                onSelect={handleSelect}
                canCreateIntervention={canCreateIntervention}
              />
            )}
            {appointment.customer_communication?.diagnose_overview_remarks && (
              <CCRemarks
                onToggleIsHandledCustomerActions={onToggleIsHandledCustomerActions}
                appointment={appointment}
                title={t("v8_diagnose_overview_remarks").message || "Diagnose overview remarks"}
                data={appointment.customer_communication.diagnose_overview_remarks}
                onSelect={handleSelect}
                canCreateIntervention={canCreateIntervention}
              />
            )}
            <CCRemarks
              onToggleIsHandledCustomerActions={onToggleIsHandledCustomerActions}
              appointment={appointment}
              title={t("v8_keylocker_remarks").message || "Keylocker remarks"}
              data={keylockerRemarks}
              onSelect={handleSelect}
              canCreateIntervention={false}
              isKeyLocker
            />
          </div>
        </div>
      </DetailsCards>

      <InterventionModal size="large" open={showInterventionModal} onClose={onRequestCloseModal} closeOnDimmerClick data={modalData} />
    </>
  );
};
