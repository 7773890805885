import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Table } from "semantic-ui-react";

import { DetailsCards, TabData } from "components";
import {
  Appointment,
  AppointmentStatusHistory,
  COMMUNICATION_EVENT_TYPE,
  CommunicationEvent,
  DESK_COMMUNICATION_STATUS,
  DeskCommunication,
  KEYLOCKER_COMMUNICATION_STATUS,
  KeylockerCommunication,
  STATUS_IDENTIFIER
} from "models";
import { CommunicationLog, DeskCommunicationLog, KeylockerCommunicationLog } from "modules/AppointmentDetails/components";
import "modules/AppointmentDetails/components/ActivityLog/ActivityLog.scss";
import { ActivityType } from "modules/AppointmentDetails/components/ActivityLog/components";
import { getCustomerName } from "util/common";
import { ITranslation } from "util/interfaces";

type ActivityLogProps = {
  appointment: Appointment;
  isExpanded: boolean;
  tabData: TabData;
};

export type ActivityLogData = {
  statusIdentifier?: STATUS_IDENTIFIER;
  communicationEventType?: COMMUNICATION_EVENT_TYPE;
  communicationStatusType?: DESK_COMMUNICATION_STATUS | KEYLOCKER_COMMUNICATION_STATUS;
  activityType: ACTIVITY_TYPE_FILTERS;
  time: string;
  note: string;
  name: string;
};

export enum ACTIVITY_TYPE_FILTERS {
  All = 0,
  Appointment,
  Customer,
  Desk,
  Kiosk,
  Keylocker,
  _LENGTH_
}

const getName = (event: CommunicationEvent | DeskCommunication | KeylockerCommunication) => {
  if (event.user) {
    return `${event.user.first_name} ${event.user.last_name}`.trim();
  } else if (event.customer) {
    return getCustomerName("customer", event.customer);
  } else if ("receivers" in event && event.receivers?.length) {
    return event.receivers[0].destination;
  } else {
    return "";
  }
};

const getFilterName = (filter: ACTIVITY_TYPE_FILTERS, t: ITranslation) => {
  switch (filter) {
    case ACTIVITY_TYPE_FILTERS.All:
      return t("v8_all").message || "All";
    case ACTIVITY_TYPE_FILTERS.Appointment:
      return t("v8_appointment").message || "Appointment";
    case ACTIVITY_TYPE_FILTERS.Customer:
      return t("v8_customer").message || "Customer";
    case ACTIVITY_TYPE_FILTERS.Desk:
      return t("v8_desk").message || "Desk";
    case ACTIVITY_TYPE_FILTERS.Kiosk:
      return t("v8_kiosk").message || "Kiosk";
    case ACTIVITY_TYPE_FILTERS.Keylocker:
      return t("v8_keylocker").message || "Keylocker";
    default:
      return "";
  }
};

const getEventName = (communicationEventType: COMMUNICATION_EVENT_TYPE, t: ITranslation) => {
  switch (communicationEventType) {
    case COMMUNICATION_EVENT_TYPE.OnlineCheckInSent:
      return t("v8_sent_online_checkin").message || "Sent online checkin";

    case COMMUNICATION_EVENT_TYPE.OnlineCheckInOpened:
      return t("v8_opened_online_checkin").message || "Opened online checkin";

    case COMMUNICATION_EVENT_TYPE.OnlineCheckInAnswered:
      return t("v8_answered_online_checkin").message || "Answered online checkin";

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewSent:
      return t("v8_sent_diagnose_overview").message || "Sent diagnose overview";

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewOpened:
      return t("v8_opened_diagnose_overview").message || "Opened diagnose overview";

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered:
      return t("v8_answered_diagnose_overview").message || "Answered diagnose overview";

    case COMMUNICATION_EVENT_TYPE.DiagnoseOverviewResent:
      return t("v8_resent_diagnose_overview").message || "Resent diagnose overview";

    case COMMUNICATION_EVENT_TYPE.RepairOverviewSent:
      return t("v8_sent_repair_overview").message || "Sent repair overview";

    case COMMUNICATION_EVENT_TYPE.RepairOverviewResent:
      return t("v8_resent_repair_overview").message || "Resent repair overview";

    case COMMUNICATION_EVENT_TYPE.RepairOverviewOpened:
      return t("v8_repair_overview_opened").message || "Repair overview opened";

    default:
      return "";
  }
};

const getKeylockerEventName = (comStatus: KEYLOCKER_COMMUNICATION_STATUS, t: ITranslation) => {
  switch (comStatus) {
    case KEYLOCKER_COMMUNICATION_STATUS.KeylockerCheckInOpened:
      return t("v8_keylocker_check_in_opened").message || "Keylocker check-in opened";

    case KEYLOCKER_COMMUNICATION_STATUS.KeylockerCheckInAnswered:
      return t("v8_keylocker_check_in_answered").message || "Keylocker check-in answered";

    case KEYLOCKER_COMMUNICATION_STATUS.KeylockerDropPinSent:
      return t("v8_keylocker_drop_pin_sent").message || "Keylocker drop pin sent";

    case KEYLOCKER_COMMUNICATION_STATUS.KeylockerPickUpPinSent:
      return t("v8_keylocker_pickup_pin_sent").message || "Keylocker pickup pin sent";

    case KEYLOCKER_COMMUNICATION_STATUS.KeylockerCheckInClosed:
      return t("v8_keylocker_check_in_closed").message || "Keylocker check-in closed";

    default:
      return "";
  }
};

export const getActivityLogData = (appointment: Appointment | undefined, t: ITranslation) => {
  const tableData: ActivityLogData[] = [];
  if (!appointment) return tableData;

  if (appointment?.customer_communication?.events?.length) {
    appointment?.customer_communication?.events.forEach((event: CommunicationEvent) => {
      tableData.push({
        time: event.created_on,
        activityType: ACTIVITY_TYPE_FILTERS.Customer,
        name: getName(event),
        note: getEventName(event.type, t),
        communicationEventType: event.type
      });
    });
  }

  if (appointment.desk_communications?.length) {
    appointment.desk_communications.forEach((event: DeskCommunication) => {
      tableData.push({
        time: event.created_on,
        activityType: ACTIVITY_TYPE_FILTERS.Desk,
        name: getName(event),
        note: event.is_checking_out ? t("v8_sent_desk_checkout").message || "Sent desk checkout" : t("v8_sent_desk_checkin").message || "Sent desk check-in",
        communicationStatusType: event.status
      });
    });
  }

  if (appointment.keylocker_communications?.length) {
    appointment.keylocker_communications.forEach((event: KeylockerCommunication) => {
      tableData.push({
        time: event.created_on,
        activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
        name: getName(event),
        note: getKeylockerEventName(event.status, t),
        communicationStatusType: event.status
      });
    });
  }

  if (appointment.car_in_shop) {
    let userName = "";
    if (appointment.car_in_shop_set_by) {
      userName = `${appointment.car_in_shop_set_by.first_name || ""} ${appointment.car_in_shop_set_by.last_name || ""}`;
    } else if (appointment.customer_owner_name) {
      userName = appointment.customer_owner_name;
    }
    tableData.push({
      time: appointment.car_in_shop,
      activityType: ACTIVITY_TYPE_FILTERS.Appointment,
      name: userName,
      note: t("v8_car_in_shop").message || "Car in Shop",
      statusIdentifier: STATUS_IDENTIFIER.CarInShop
    });
  }

  if (appointment?.car_out_of_shop) {
    tableData.push({
      time: appointment.car_out_of_shop,
      activityType: ACTIVITY_TYPE_FILTERS.Appointment,
      name: appointment.car_out_of_shop_set_by ? `${appointment.car_out_of_shop_set_by.first_name || ""} ${appointment.car_out_of_shop_set_by.last_name || ""}` : "",
      note: t("v8_car_out_of_shop").message || "Car out of shop",
      statusIdentifier: STATUS_IDENTIFIER.CarOutOfShop
    });
  }

  appointment?.status_history?.forEach((status: AppointmentStatusHistory) => {
    let name = status.last_user;
    if (status.user_id === 0 && status.identifier === STATUS_IDENTIFIER.CustomerAnsweredStatus) {
      const eventDiagnoseOverviewAnswered = appointment.customer_communication?.events?.find(event => event.type === COMMUNICATION_EVENT_TYPE.DiagnoseOverviewAnswered);

      if (eventDiagnoseOverviewAnswered) name = getName(eventDiagnoseOverviewAnswered);
    }
    tableData.push({
      time: status.timestamp,
      activityType: ACTIVITY_TYPE_FILTERS.Appointment,
      name: status.identifier === STATUS_IDENTIFIER.CanceledStatus && status.is_dms ? t("v8_canceled_by_dms").message || "Canceled by DMS" : name,
      note: status.checklist_name || "",
      statusIdentifier: status.identifier
    });
  });

  if (appointment?.check_paused_at) {
    tableData.push({
      time: appointment.check_paused_at,
      activityType: ACTIVITY_TYPE_FILTERS.Appointment,
      name: "",
      note: t("paused_check").message || "Paused check",
      statusIdentifier: STATUS_IDENTIFIER.CarCheckStartedStatus
    });
  }

  if (appointment?.sharebox_key_dropped_at) {
    tableData.push({
      time: appointment.sharebox_key_dropped_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: "",
      note: t("v8_keylocker_sharebox_key_dropped").message || "Sharebox key dropped"
    });
  }

  if (appointment?.sharebox_key_picked_up_at) {
    tableData.push({
      time: appointment.sharebox_key_picked_up_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: appointment.sharebox_key_picked_up_by ? `${appointment.sharebox_key_picked_up_by.first_name} ${appointment.sharebox_key_picked_up_by.last_name}`.trim() : "",
      note: t("v8_keylocker_sharebox_key_picked_up").message || "Sharebox key picked up"
    });
  }

  if (appointment?.sharebox_key_dropped_back_at) {
    tableData.push({
      time: appointment.sharebox_key_dropped_back_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: appointment.sharebox_key_dropped_back_by
        ? `${appointment.sharebox_key_dropped_back_by.first_name} ${appointment.sharebox_key_dropped_back_by.last_name}`.trim()
        : "",
      note: t("v8_keylocker_sharebox_key_dropped_back").message || "Sharebox key dropped back"
    });
  }

  if (appointment?.sharebox_key_picked_up_back_at) {
    tableData.push({
      time: appointment.sharebox_key_picked_up_back_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: appointment.sharebox_key_picked_up_back_by
        ? `${appointment.sharebox_key_picked_up_back_by.first_name} ${appointment.sharebox_key_picked_up_back_by.last_name}`.trim()
        : "",
      note: t("v8_keylocker_sharebox_key_picked_up_back").message || "Sharebox key picked up back"
    });
  }

  if (appointment?.acses_key_dropped_at) {
    tableData.push({
      time: appointment.acses_key_dropped_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: "",
      note: t("v8_keylocker_acses_key_dropped").message || "Acses key dropped"
    });
  }

  if (appointment?.acses_key_picked_up_at) {
    tableData.push({
      time: appointment.acses_key_picked_up_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: appointment.acses_key_picked_up_by ? `${appointment.acses_key_picked_up_by.first_name} ${appointment.acses_key_picked_up_by.last_name}`.trim() : "",
      note: t("v8_keylocker_acses_key_picked_up").message || "Acses key picked up"
    });
  }

  if (appointment?.acses_key_dropped_back_at) {
    tableData.push({
      time: appointment.acses_key_dropped_back_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: appointment.acses_key_dropped_back_by ? `${appointment.acses_key_dropped_back_by.first_name} ${appointment.acses_key_dropped_back_by.last_name}`.trim() : "",
      note: t("v8_keylocker_acses_key_dropped_back").message || "Acses key dropped back"
    });
  }

  if (appointment?.acses_key_picked_up_back_at)
    tableData.push({
      time: appointment.acses_key_picked_up_back_at,
      activityType: ACTIVITY_TYPE_FILTERS.Keylocker,
      name: appointment.acses_key_picked_up_back_by
        ? `${appointment.acses_key_picked_up_back_by.first_name} ${appointment.acses_key_picked_up_back_by.last_name}`.trim()
        : "",
      note: t("v8_keylocker_acses_key_picked_up_back").message || "Acses key picked up back"
    });

  return tableData.sort((t1: ActivityLogData, t2: ActivityLogData) => {
    return t2.time.localeCompare(t1.time);
  });
};

const getFilterOptions = (activityLogData: ActivityLogData[], t: ITranslation) => {
  const activityTypeFilters = new Set<ACTIVITY_TYPE_FILTERS>([ACTIVITY_TYPE_FILTERS.All]);
  for (const activity of activityLogData) {
    if (activityTypeFilters.size === ACTIVITY_TYPE_FILTERS._LENGTH_) {
      break;
    }
    activityTypeFilters.add(activity.activityType);
  }
  return Array.from(activityTypeFilters).map(filter => ({
    key: filter,
    value: filter,
    text: getFilterName(filter, t)
  }));
};

const renderBodyRow = (activity: ActivityLogData) => {
  return {
    cells: [
      {
        key: "icon",
        content: (
          <ActivityType
            activityType={activity.activityType}
            statusIdentifier={activity.statusIdentifier}
            communicationStatusType={activity.communicationStatusType}
            communicationEventType={activity.communicationEventType}
          />
        )
      },
      { key: "name", content: activity.name },
      { key: "time", content: moment(activity.time).format("DD-MM-YYYY HH:mm") },
      { key: "note", content: activity.note }
    ]
  };
};

export const ActivityLog = ({ isExpanded, tabData, appointment }: ActivityLogProps) => {
  const t = useTranslation().t as ITranslation;

  const activityTypeFilterOptions = getFilterOptions(tabData.data, t);
  const [activityTypeFilter, setActitivtyTypeFilter] = useState<ACTIVITY_TYPE_FILTERS>(ACTIVITY_TYPE_FILTERS.All);
  const [activityLogData, setActivityLogData] = useState<ActivityLogData[]>(tabData.data);

  useEffect(() => {
    setActivityLogData(tabData.data);
  }, [tabData.data]);

  const headerRow = [
    t("v8_activity").message || "Activity",
    t("v8_user_customer").message || "User / Customer",
    t("v8_time").message || "Time",
    t("v8_note").message || "Note"
  ];

  const handleActivityTypeFilterChange = (value: ACTIVITY_TYPE_FILTERS) => {
    setActitivtyTypeFilter(value);
    let filteredActivityLogData: ActivityLogData[] = tabData.data;
    if (value !== ACTIVITY_TYPE_FILTERS.All) {
      filteredActivityLogData = filteredActivityLogData.filter(d => d.activityType === value);
    }
    setActivityLogData(filteredActivityLogData);
    return filteredActivityLogData;
  };

  return (
    <DetailsCards
      id={tabData.id}
      title={`${t("v8_activity_log").message || "Activity Log"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
      icon="user clock"
      isExpanded={isExpanded}
      rightContent={
        activityTypeFilterOptions.length > 2 ? (
          <div className="Activitylog-filter">
            <label>{t("v8_activity_type").message || "Activity Type"}</label>
            <Dropdown
              selectOnBlur={false}
              value={activityTypeFilter}
              options={activityTypeFilterOptions}
              selection
              onChange={(_, { value }) => handleActivityTypeFilterChange(value as ACTIVITY_TYPE_FILTERS)}
            />
          </div>
        ) : null
      }
    >
      <Table tableData={activityLogData} headerRow={headerRow} renderBodyRow={renderBodyRow} className="mt-20" />
      {!!appointment?.customer_communication?.events?.length && (
        <div className="AppointmentDetails-cards mt-15">
          <CommunicationLog appointment={appointment} />
        </div>
      )}
      {!!appointment?.desk_communications?.length && (
        <div className="AppointmentDetails-cards mt-15">
          <DeskCommunicationLog appointment={appointment} />
        </div>
      )}
      {!!appointment?.keylocker_communications?.length && (
        <div className="AppointmentDetails-cards mt-15">
          <KeylockerCommunicationLog appointment={appointment} />
        </div>
      )}
    </DetailsCards>
  );
};
