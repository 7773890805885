import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Dropdown, Form, Grid, GridColumn, GridRow, Icon, Input, Modal, ModalProps, Popup } from "semantic-ui-react";

import { Editable, useCan } from "components";
import { useDealersLocations } from "hooks";
import { Appointment, QuestionResult, SearchTyreReplacement, TYRE_SEASONS, TYRE_TEAM_CHANNEL } from "models";
import "modules/AppointmentDetails/components/Tyre/components/TyreReplacementModal/TyreReplacementModal.scss";
import useTyre from "modules/AppointmentDetails/components/Tyre/hooks/useTyre";
import { getTyreSeasonIcon, isCarReadyOrQualityCheckDoneOrCanceled, removeDuplicates, roundTwoDecimals } from "util/common";
import { ITranslation } from "util/interfaces";

interface TyreReplacementModalProps extends ModalProps {
  data: QuestionResult | null;
  onRequestClose?: () => void;
  appointment: Appointment;
}

type Delivery = {
  order_before: string;
  delivery_before: string;
  delivery_from: string;
  id: string;
};

export type TyreData = {
  price: number;
  question_result_id: number;
  tyre_team: SearchTyreReplacement;
  tyre_id?: number;
};

export const TyreReplacementModal = ({ data, open, onRequestClose, appointment }: TyreReplacementModalProps) => {
  const { brandsQuery, deliveryQuery, tyreSearchMutation, addTyres } = useTyre(Number(appointment.id));
  const { selectedLocation } = useDealersLocations();
  const [manufacturer, setManufacturer] = useState("");
  const [season, setSeason] = useState<TYRE_SEASONS>(TYRE_SEASONS.AllSeason);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [size, setSize] = useState(0);
  const [inStock, setInStock] = useState(true);
  const [delivery, setDelivery] = useState<Delivery[]>([]);
  const [selection, setSelection] = useState<Record<string, TyreData> | null>(null);
  const [searchResult, setSearchResult] = useState<SearchTyreReplacement[]>([]);
  const canUpdateAppointments = useCan("update", "appointments");
  const t = useTranslation().t as ITranslation;

  const isTyreTeamEnabled = selectedLocation?.is_tyre_team_enabled;

  useEffect(() => {
    if (data?.tyre) {
      setManufacturer(data.tyre.manufacturer);
      setSeason(data.tyre.season);
      setWidth(data.tyre.width);
      setHeight(data.tyre.height);
      setSize(data.tyre.size);
      setSelection(null);
    }
  }, [data?.tyre, open]);

  useEffect(() => {
    if (deliveryQuery.data) {
      setDelivery(deliveryQuery.data.filter((d: Delivery) => !d.id.includes("PICKUP")));
    }
  }, [deliveryQuery.data]);

  const seasonOptions = [
    {
      key: TYRE_SEASONS.AllSeason,
      value: TYRE_SEASONS.AllSeason,
      text: t("v8_all_seasons").message || "All season"
    },
    {
      key: TYRE_SEASONS.Summer,
      value: TYRE_SEASONS.Summer,
      text: t("v8_summer_season").message || "Summer"
    },

    {
      key: TYRE_SEASONS.Winter,
      value: TYRE_SEASONS.Winter,
      text: t("v8_winter_season").message || "Winter"
    }
  ];

  const getBrandsData = () => {
    return (brandsQuery?.data || [])?.map((item: string) => {
      return {
        key: item,
        value: item,
        text: item
      };
    });
  };

  const getDeliveryChannel = (channel: TYRE_TEAM_CHANNEL) => {
    switch (channel) {
      case TYRE_TEAM_CHANNEL.WheelTyre:
        return "Wheel-Tyre";
      case TYRE_TEAM_CHANNEL.BandenExpress:
        return "Banden Express";

      default:
        return t("v8_unknown_channel").message || "Unknown channel";
    }
  };

  const getDeliveryLong = (delivery: Delivery) => {
    const { order_before, delivery_before, delivery_from } = {
      order_before: delivery.order_before ? moment(delivery.order_before).format("HH:mm") : null,
      delivery_before: delivery.delivery_before ? moment(delivery.delivery_before).format("HH:mm") : null,
      delivery_from: delivery.delivery_from ? moment(delivery.delivery_from).format("HH:mm") : null
    };

    if (delivery.id.includes("PICKUPSAMEDAY"))
      return `${t("v8_tyre_team_pickup_sameday").message || t("v8_pickup_same_day").message || "Pickup same day"} ${order_before} ${delivery_from}`;
    if (delivery.id.includes("SAMEDAY")) return `${t("v8_tyre_team_sameday").message || t("v8_same_day").message || "Same day"} ${order_before} ${delivery_before}`;
    if (delivery.id.includes("PICKUPOVERNIGHT"))
      return `${t("v8_tyre_team_pickupovernight").message || t("v8_pickup_overnight").message || "Pickup overnight"} ${order_before} ${delivery_from}`;
    if (delivery.id.includes("OVERNIGHT")) return `${t("v8_tyre_team_overnight").message || t("v8_overnight").message || "Overnight"} ${order_before} ${delivery_before}`;

    return "";
  };

  const getDelivery = (delivery: string) => {
    const selectedDelivery = deliveryQuery.data?.find((d: Delivery) => d.id === delivery) || {};

    const { order_before, delivery_before, delivery_from } = {
      order_before: selectedDelivery.order_before ? moment(selectedDelivery.order_before).format("HH:mm") : null,
      delivery_before: selectedDelivery.delivery_before ? moment(selectedDelivery.delivery_before).format("HH:mm") : null,
      delivery_from: selectedDelivery.delivery_from ? moment(selectedDelivery.delivery_from).format("HH:mm") : null
    };

    if (delivery.includes("PICKUPSAMEDAY"))
      return (
        <Popup
          content={<span>{`${t("v8_tyre_team_pickup_sameday").message || t("v8_pickup_same_day").message || "Pickup same day"} ${order_before} ${delivery_from}`}</span>}
          trigger={<span>{t("v8_pickup_sameday").message || "Pickup sameday"}</span>}
        />
      );

    if (delivery.includes("SAMEDAY"))
      return (
        <Popup
          content={<span>{`${t("v8_tyre_team_sameday").message || t("v8_same_day").message || "Same day"} ${order_before} ${delivery_before}`}</span>}
          trigger={<span>{t("v8_sameday").message || "Sameday"}</span>}
        />
      );

    if (delivery.includes("PICKUPOVERNIGHT"))
      return (
        <Popup
          content={
            <span>{`${t("v8_tyre_team_pickupovernight").message || t("v8_pickup_overnight").message || "Pickup overnight"} ${order_before} ${delivery_from}`}</span>
          }
          trigger={<span>{t("v8_pickup_overnight").message || "Pickup Overnight"}</span>}
        />
      );

    if (delivery.includes("OVERNIGHT")) {
      return (
        <Popup
          content={<span>{`${t("v8_tyre_team_overnight").message || t("v8_overnight").message || "Overnight"} ${order_before} ${delivery_before}`}</span>}
          trigger={<span>{t("v8_overnight").message || "Overnight"}</span>}
        />
      );
    }

    return "";
  };

  const getDeliveriesData = () => {
    return (deliveryQuery?.data || []).map((item: Delivery) => {
      return {
        key: item,
        value: item,
        text: getDeliveryLong(item)
      };
    });
  };

  const handleSearch = () => {
    tyreSearchMutation.mutate({
      manufacturer,
      season,
      tyre_team_delivery_ids: delivery.map(item => item.id),
      in_stock: inStock,
      height,
      width,
      size,
      id: Number(selectedLocation?.id)
    });
  };

  useEffect(() => {
    if (brandsQuery.isSuccess && open && data?.tyre?.manufacturer === manufacturer) {
      handleSearch();
    }
  }, [brandsQuery.isSuccess, deliveryQuery.isSuccess, open, manufacturer, data, selectedLocation?.is_tyre_team_enabled]);

  const handleSelect = (index: number, checked: boolean) => {
    const tyreTeam = searchResult[index];
    setSelection(prev => {
      const prevSelection = { ...prev };
      if (checked) {
        prevSelection[tyreTeam.ean] = {
          price: tyreTeam.price || tyreTeam.gross_price,
          question_result_id: Number(data?.id),
          tyre_team: tyreTeam
        };
      } else {
        delete prevSelection[tyreTeam.ean];
      }
      return prevSelection;
    });
  };

  const handleAddTyre = async () => {
    if (selection) {
      addTyres(selection).then(() => onRequestClose?.());
    }
  };

  const getNumber = (n: string) => {
    if (isNaN(Number(n))) return 0;
    return Number(n);
  };

  useEffect(() => {
    if (tyreSearchMutation.data) {
      setSearchResult(removeDuplicates(tyreSearchMutation.data || [], "ean"));
    }
  }, [tyreSearchMutation.data]);

  const handlePriceUpdate = (price: string, index: number) => {
    setSearchResult(prevData => {
      const result = [...prevData];
      result[index].price = Number(price);
      return result;
    });
  };

  return (
    <Modal className="TyreReplacementModal" closeOnEscape size="fullscreen" dimmer="blurring" open={open} onClose={onRequestClose} closeOnDimmerClick={true}>
      <Modal.Header>
        <Icon className="tire" />
        <p>{t("v8_add_replacement").message || "Add Replacement"}</p>
      </Modal.Header>
      <Modal.Content scrolling style={{ height: "70vh" }}>
        <div className="TyreReplacementModal">
          <Form onSubmit={handleSearch}>
            <Form.Group>
              <Form.Field width={2} required>
                <label>{t("v8_width").message || "Width"}</label>
                <Input
                  value={width}
                  placeholder={t("v8_width").message || "Width"}
                  fluid
                  name="width"
                  required
                  onChange={e => setWidth(Number(getNumber(e.target.value)))}
                />
              </Form.Field>

              <Form.Field width={2}>
                <label>{t("v8_height").message || "Height"}</label>
                <Input value={height} placeholder={t("v8_height").message || "Height"} fluid name="height" onChange={e => setHeight(Number(getNumber(e.target.value)))} />
              </Form.Field>

              <Form.Field width={2} disabled={height <= 0}>
                <label>{t("v8_size").message || "Size"}</label>
                <Input
                  value={size}
                  placeholder={t("v8_size").message || "Size"}
                  fluid
                  name="size"
                  type="number"
                  onChange={e => setSize(Number(getNumber(e.target.value)))}
                />
              </Form.Field>

              <Form.Field width={4}>
                <label>{t("v8_brand").message || "Brand"}</label>
                <Dropdown
                  selection
                  selectOnBlur={false}
                  loading={brandsQuery.isLoading}
                  options={getBrandsData()}
                  clearable
                  className="DeliveryDropdown"
                  search
                  value={manufacturer}
                  onChange={(_evt, { value }) => setManufacturer(String(value))}
                  name="manufacturer"
                />
              </Form.Field>

              <Form.Field width={3}>
                <label>{t("season").message || "Season"}</label>
                <Dropdown
                  selection
                  className="DeliveryDropdown"
                  selectOnBlur={false}
                  clearable
                  options={seasonOptions}
                  value={season}
                  onChange={(_evt, { value }) => setSeason(Number(value))}
                  name="season"
                />
              </Form.Field>

              {isTyreTeamEnabled && (
                <>
                  <Form.Field width={6} required>
                    <label>{t("delivery").message || "Delivery"}</label>
                    <Dropdown
                      selection
                      selectOnBlur={false}
                      clearable
                      className="DeliveryDropdown"
                      value={delivery as unknown as string[]}
                      options={getDeliveriesData()}
                      placeholder={t("select_deliveries").message || "Select deliveries"}
                      loading={deliveryQuery.isLoading}
                      onChange={(_evt, { value }) => setDelivery(value as unknown as Delivery[])}
                      name="delivery"
                      multiple
                    />
                  </Form.Field>

                  <Form.Field width={1} style={{ textAlign: "center" }}>
                    <label>{t("in_stock").message || "In stock"}</label>
                    <Checkbox toggle style={{ marginTop: "7px" }} defaultChecked={inStock} name="in_stock" onChange={(_e, data) => setInStock(Boolean(data.checked))} />
                  </Form.Field>
                </>
              )}

              <Form.Field className="no-padding-right">
                <label style={{ visibility: "hidden" }}>Search</label>
                <Button
                  type="submit"
                  disabled={(isTyreTeamEnabled && !delivery.length) || brandsQuery.isLoading}
                  loading={brandsQuery.isLoading}
                  style={{ color: "#000", fontWeight: "600" }}
                >
                  {t("search").message || "Search"}
                </Button>
              </Form.Field>
            </Form.Group>
          </Form>

          <Grid>
            <GridRow className="header">
              <GridColumn width={1} />
              {isTyreTeamEnabled ? (
                <>
                  <GridColumn width={3}>
                    <p>{t("v8_description").message || "Description"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_brand").message || "Brand"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{"Art / EAN"} #</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_gross").message || "Gross"} €</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_neto").message || "Neto"} €</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_customer").message || "Customer"} €</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_load").message || "Load"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_speed").message || "Speed"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_flat_tire").message || "Flat Tire"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_channel").message || "Channel"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_stock").message || "Stock"}</p>
                  </GridColumn>
                  <GridColumn width={1}>
                    <p>{t("v8_delivery").message || "Delivery"}</p>
                  </GridColumn>
                </>
              ) : (
                <>
                  <GridColumn width={6}>
                    <p>{t("v8_description").message || "Description"}</p>
                  </GridColumn>
                  <GridColumn width={6}>
                    <p>{t("v8_brand").message || "Brand"}</p>
                  </GridColumn>
                  <GridColumn width={2}>
                    <p>{t("v8_price").message || "Price"}</p>
                  </GridColumn>
                </>
              )}
              <GridColumn width={1}>
                <p>{t("v8_add").message || "Add"}</p>
              </GridColumn>
            </GridRow>
            {searchResult.map((item, index: number) => {
              return (
                <GridRow key={index}>
                  <GridColumn width={1}>
                    <Icon className={getTyreSeasonIcon(Number(item.season))} />
                  </GridColumn>
                  {isTyreTeamEnabled ? (
                    <>
                      <GridColumn width={3}>
                        <p>{item.description}</p>
                      </GridColumn>
                      <GridColumn width={1}>
                        <p>{item.manufacturer}</p>
                      </GridColumn>
                      <GridColumn width={1}>
                        <div>
                          {item.system_number}
                          {item.ean && (
                            <>
                              {" / "}
                              <Popup hoverable trigger={<div className="replacement-tyre-ean">{item.ean}</div>} content={item.ean} />
                            </>
                          )}
                        </div>
                      </GridColumn>
                      <GridColumn width={1}>
                        {item.stock && item.gross_price ? (
                          <p>
                            {item.gross_price} {item.discount && `- ${item.discount}%`}
                          </p>
                        ) : (
                          <p>0.0</p>
                        )}
                      </GridColumn>{" "}
                      <GridColumn width={1} className={`${item.customerPrice && item.customerPrice < item.net_price ? "lower-customer-price" : ""}`}>
                        {item.stock && item.net_price ? (
                          <p>
                            {item.net_price} {item.price_reduction && `- ${item.price_reduction}%`}
                          </p>
                        ) : (
                          <p>0.0</p>
                        )}
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.customerPrice ? roundTwoDecimals(Number(item.customerPrice)).toFixed(2) : null}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.carry_capacity}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.speed}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>{item.run_flat_tire ? <Icon name="check circle" color="green" /> : ""}</GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{getDeliveryChannel(item.channel)}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{item.stock ? item.stock : 0}</p>
                      </GridColumn>{" "}
                      <GridColumn width={1}>
                        <p>{getDelivery(item.delivery)}</p>
                      </GridColumn>{" "}
                    </>
                  ) : (
                    <>
                      <GridColumn width={6}>
                        <p>{item.description}</p>
                      </GridColumn>
                      <GridColumn width={6}>
                        <p>{item.manufacturer}</p>
                      </GridColumn>
                      <GridColumn width={2}>
                        <Editable
                          value={String(item.gross_price || "")}
                          placeholder="0.00"
                          onSubmit={(price: string) => handlePriceUpdate(price, index)}
                          disabled={!canUpdateAppointments || isCarReadyOrQualityCheckDoneOrCanceled(appointment)}
                        />
                      </GridColumn>
                    </>
                  )}
                  <GridColumn width={1}>
                    <Checkbox className="check" checked={Boolean(selection?.[item.ean])} onChange={(_e, data) => handleSelect(index, Boolean(data.checked))} />
                  </GridColumn>
                </GridRow>
              );
            })}
          </Grid>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="action-buttons">
          <Button color="light" onClick={onRequestClose} type="button">
            {t("v8_cancel").message || "Cancel"}
            <Icon className="xmark" />
          </Button>
          <Button color="green" form="new-intervention" type="button" onClick={handleAddTyre}>
            {t("v8_add").message || "Add"}
            <Icon className="check" />
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
