import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Icon, Popup } from "semantic-ui-react";

import { ActionModalSelector, ActionModalSelectorData, AttachmentPreview } from "components";
import { Appointment, CheckInRemark, DiagnoseOverviewRemark, KeylockerRemark, QuestionResult } from "models";
import { getExtensionFromURL } from "util/common";
import { ITranslation } from "util/interfaces";

type CCRemarksProps = {
  title: string;
  data?: CheckInRemark[] | DiagnoseOverviewRemark[] | KeylockerRemark[];
  canCreateIntervention: boolean;
  onSelect: (data: ActionModalSelectorData) => void;
  isKeyLocker?: boolean;
  appointment: Appointment;
  onToggleIsHandledCustomerActions: (data: CheckInRemark | KeylockerRemark | DiagnoseOverviewRemark) => void;
};
export const CCRemarks = ({ title, data = [], canCreateIntervention, onSelect, isKeyLocker, onToggleIsHandledCustomerActions, appointment }: CCRemarksProps) => {
  if (!data.length) return null;

  const [visibleCustomerActionAttachmentId, setVisibleCustomerActionAttachmentId] = useState<number | null>();
  const t = useTranslation().t as ITranslation;

  const showMedia = (id: number) => {
    setVisibleCustomerActionAttachmentId(current => (current === id ? null : id));
  };

  return (
    <div className="CustomerActions-items">
      {data
        .sort((a, b) => a.id - b.id)
        .map(item => {
          if (!item) return null;

          const actionModelData = {
            title: item.title,
            raw: item.description,
            solution: "",
            appointment_id: appointment.id,
            checkin_remark_id: item.id
          };

          const attachments = item.attachments?.map(attachment => ({
            id: attachment.id,
            url: attachment.url,
            name: attachment.name,
            created_on: attachment.created_on,
            updated_on: attachment.updated_on,
            type: getExtensionFromURL(attachment.url)
          }));

          const isDiagnoseOverviewOrAlreadyConverted =
            appointment?.customer_communication?.diagnose_overview_remarks?.some(remark => remark.id === item.id) ||
            appointment?.interventions?.some(intervention => intervention.check_in_remark_id === item.id);

          return (
            <Grid key={item.id} className="actions-container">
              <Grid.Column width={13}>
                <div className={`icon-container ${isKeyLocker ? "green" : "blue"}`}>
                  <Popup
                    wide
                    disabled={!item.customer_name}
                    basic
                    content={
                      <div className="InfoPopup">
                        <div className="InfoTitle">{title}</div>
                        <div className="InfoDescription">
                          {t("v8_made_by").message || "Made by"} {item.customer_name}
                        </div>
                        <div className="InfoContent">
                          <span>{t("v8_on").message || "On"}</span> {`${moment(item.created_on).format("DD-MM-YYYY HH:mm")}`}
                        </div>
                      </div>
                    }
                    trigger={<Icon className={isKeyLocker ? "key" : "note sticky"} />}
                  />
                </div>
                {item.title && <p className="title">{item.title}</p>}
                {item.description && <p className="description">{item.description}</p>}
              </Grid.Column>
              <Grid.Column width={3}>
                <div className="end-grid">
                  {!isKeyLocker && !!item.attachments?.length && (
                    <div onClick={() => showMedia(item.id)} className="image-action">
                      <Icon className="images grey pointer" size="small" />
                      <p>
                        <small>{item.attachments.length}</small>
                      </p>
                    </div>
                  )}
                  <div className="status-container">
                    <div className="changeHandleStatus" onClick={() => onToggleIsHandledCustomerActions(item)}>
                      <Icon className={item.receptionist_handled ? "check circle outline green" : "circle xmark outline red"} />
                    </div>

                    {canCreateIntervention && (
                      <ActionModalSelector
                        isConvertToInterventionButtonDisabled={isDiagnoseOverviewOrAlreadyConverted}
                        data={actionModelData as QuestionResult}
                        attachments={attachments}
                        onSelect={onSelect}
                        onlyIntervention
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
              {visibleCustomerActionAttachmentId === item.id && (
                <Grid.Column width={16}>
                  <AttachmentPreview attachments={attachments} />
                </Grid.Column>
              )}
            </Grid>
          );
        })}
    </div>
  );
};
