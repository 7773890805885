import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Icon, Image, Label, Popup } from "semantic-ui-react";

import { AppointmentStatusCell, useCan } from "components";
import { DAYPLANNER_COLUMNS, useDayplannerContext } from "modules/Dayplanner";
import "modules/Dayplanner/components/AppointmentCard/AppointmentCard.scss";
import { CarQuickView, InterventionQuickView, NotificationIcons } from "modules/Dayplanner/components/AppointmentCard/components";
import { Appointment, MechanicWithAppointments } from "modules/Dayplanner/utils";
import { classNames, getInitials } from "util/common";
import { ITranslation } from "util/interfaces";

type AppointmentCardProps = {
  appointment: Appointment;
  column: DAYPLANNER_COLUMNS;
  mechanic?: MechanicWithAppointments;
  collapsedColumns?: DAYPLANNER_COLUMNS[];
};

type AppointmentContentProps = {
  appointment: Appointment;
  column: DAYPLANNER_COLUMNS;
  collapsedColumns?: DAYPLANNER_COLUMNS[];
  mechanic?: MechanicWithAppointments;
};

type MechanicInfoProps = {
  appointment: Appointment;
  column: DAYPLANNER_COLUMNS;
};

const MechanicInfo = ({ appointment, column }: MechanicInfoProps) => {
  const t = useTranslation().t as ITranslation;
  const { columns } = useDayplannerContext();

  if (!column && !appointment?.planning_mechanic && !appointment.last_assigned_mechanic) return null;

  const mechanic = columns.mechanicsColumn.find(m => {
    if (column === DAYPLANNER_COLUMNS.CarReady) return m.id === appointment.last_assigned_mechanic;
    return m.id === appointment.planning_mechanic?.id;
  });

  if (!mechanic) return null;

  const content = (
    <div>
      <span>
        {mechanic.first_name} {mechanic.last_name}
      </span>
      {column !== DAYPLANNER_COLUMNS.CarReady && (
        <>
          <div>{`${t("start_time").message || "Start time"}: ${moment(appointment.planning_work_start).format("DD-MM-YYYY HH:mm")}`}</div>
          <div>{`${t("end_time").message || "End time"}: ${moment(appointment.planning_work_stop).format("DD-MM-YYYY HH:mm")}`}</div>
        </>
      )}
    </div>
  );

  const trigger = mechanic.profile_picture ? (
    <Image src={mechanic.profile_picture} avatar />
  ) : (
    <div className="initials-fallback">{getInitials(mechanic.first_name, mechanic.last_name)}</div>
  );

  return <Popup content={content} trigger={trigger} position="top right" />;
};

const AppointmentContent = ({ appointment, column, mechanic, collapsedColumns }: AppointmentContentProps) => {
  const history = useHistory();
  const { onDragAppointmentStart, onDraggedAppointmentEnterAnotherAppointment } = useDayplannerContext();
  const canAssignWo = useCan("assign", "dayplanner");

  const planningWorkStop = appointment.car_return_time || appointment.planning_work_stop;
  const isDraggable = canAssignWo && [DAYPLANNER_COLUMNS.Unassigned, DAYPLANNER_COLUMNS.Mechanics].includes(column);
  const boardItemClassNames = classNames({
    "-bg-color-red": !!(planningWorkStop && moment(planningWorkStop).diff(moment(), "minutes") <= 0),
    "-bg-color-yellow": !!(planningWorkStop && moment(planningWorkStop).diff(moment(), "minutes") <= 60 && moment(planningWorkStop).diff(moment(), "minutes") > 0),
    "-appointment-internal": appointment.internal,
    "-panic": appointment.has_panic,
    "-popup": collapsedColumns?.includes(column),
    "-inactive": column === DAYPLANNER_COLUMNS.Mechanics && appointment.inactive
  });

  const onAppointmentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (isDraggable) return;
    history.push(`/appointments/${appointment.id}`);
  };

  const handleCarDetailLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    history.push(`/cardetails/${appointment.car_id}`);
  };

  const handleAppointmentDetailsLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    history.push(`/appointments/${appointment.id}`);
  };

  return (
    <div
      key={`app-${appointment.id}`}
      className={`BoardItem ${boardItemClassNames}`}
      onClick={onAppointmentClick}
      data-mechanic-order={appointment.assigned_mechanic_order}
      data-mechanic-id={appointment.assigned_mechanic || null}
      data-appointment-id={appointment.id}
      draggable={isDraggable}
      onDragStart={e => onDragAppointmentStart(e, appointment, mechanic)}
      onDragEnter={e => isDraggable && onDraggedAppointmentEnterAnotherAppointment(e)}
      onMouseDown={e => e.stopPropagation()}
      onDragLeave={e => e.preventDefault()}
      onDragOver={e => e.preventDefault()}
    >
      <div className="BoardItem-appointment-status -expanded">
        <AppointmentStatusCell
          value={{
            id: appointment.appointment_status_identifier,
            car_out_of_shop: appointment.car_out_of_shop,
            customcom_status: appointment.customer_communication?.status,
            check_paused_at: appointment.check_paused_at
          }}
        />

        {appointment.kiosk_label_number && (
          <Label className="BoardItem-kiosk-label">
            <Icon className="tag" />
            <span>{appointment.kiosk_label_number}</span>
          </Label>
        )}
      </div>

      <div className="BoardItem-info -cursor-move">
        <CarQuickView
          appointment={appointment}
          popupTrigger={
            <span className="-cursor-pointer" onClick={handleCarDetailLink}>
              {appointment.reg_number ? `${appointment.reg_number}` : ""}
            </span>
          }
        />

        {appointment.reg_number ? " - " : ""}

        <InterventionQuickView
          appointment={appointment}
          popupTrigger={
            <span className="-cursor-pointer wo-nr" onClick={handleAppointmentDetailsLink}>
              {appointment.wo_nr}
            </span>
          }
        />
      </div>

      <div className="BoardItem-last-update">{planningWorkStop && moment(planningWorkStop).format("DD-MM-YYYY HH:mm")}</div>

      <div className="BoardItem-bottom-elements">
        <NotificationIcons appointment={appointment} />

        {[DAYPLANNER_COLUMNS.Unassigned, DAYPLANNER_COLUMNS.CarReady].includes(column) && (
          <div className="BoardItem-planning-mechanic">
            <MechanicInfo appointment={appointment} column={column} />
          </div>
        )}
      </div>
    </div>
  );
};

export const AppointmentCard = ({ appointment, column, mechanic, collapsedColumns }: AppointmentCardProps) => {
  if (appointment.inactive && column !== DAYPLANNER_COLUMNS.Mechanics) return null;
  const isMechanicColumn = column === DAYPLANNER_COLUMNS.Mechanics;
  return (
    <div className={`AppointmentCard ${isMechanicColumn ? "-mechanic" : ""}`}>
      {collapsedColumns?.includes(column) ? (
        <Popup
          basic
          hoverable
          size="large"
          trigger={
            <Link className="BoardItem-appointment-status -collapsed" to={`/appointments/${appointment.id}`}>
              <AppointmentStatusCell
                value={{
                  id: appointment.appointment_status_identifier,
                  car_out_of_shop: appointment.car_out_of_shop,
                  customcom_status: appointment.customer_communication?.status,
                  check_paused_at: appointment.check_paused_at
                }}
              />
            </Link>
          }
          content={
            <div className="BoardItem-appointment-status">
              <AppointmentContent appointment={appointment} mechanic={mechanic} column={column} collapsedColumns={collapsedColumns} />
            </div>
          }
          style={{ padding: "0", boxShadow: "none", border: "none" }}
        />
      ) : (
        <AppointmentContent mechanic={mechanic} appointment={appointment} column={column} collapsedColumns={collapsedColumns} />
      )}
    </div>
  );
};
