import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "semantic-ui-react";

import { DetailsCards, TabData, useCan } from "components";
import { useDealersLocations, useUser } from "hooks";
import { APPOINTMENT_NOTE_TYPES, Appointment, AppointmentNote, AppointmentNoteAttachment, STATUS_IDENTIFIER } from "models";
import "modules/AppointmentDetails/components/AppointmentNotes/AppointmentNotes.scss";
import { AddUpdateAppointmentNote, AppointmentNoteRow, AppointmentNoteTypeIcon, DMSNotes } from "modules/AppointmentDetails/components/AppointmentNotes/components";
import { useGetDropdownOptions } from "modules/AppointmentDetails/components/AppointmentNotes/hooks";
import { useAppointmentNotes, useUploadAppointmentNoteAttachment } from "modules/AppointmentDetails/hooks";
import { ITranslation } from "util/interfaces";

type AppointmentNotesProps = {
  appointment: Appointment;
  isExpanded: boolean;
  tabData: TabData;
};

export const AppointmentNotes = ({ appointment, tabData, isExpanded }: AppointmentNotesProps) => {
  if (!appointment) return null;

  const t = useTranslation().t as ITranslation;

  const { addAppointmentNote, updateAppointmentNote, deleteAppointmentNote } = useAppointmentNotes(appointment.id);
  const { handleUploadAppointmentNoteAttachment } = useUploadAppointmentNoteAttachment();
  const { noteTypeOptions, phoneOptions } = useGetDropdownOptions({ appointment, t });
  const { selectedLocation } = useDealersLocations();
  const user = useUser();

  const [showCardDetails, setShowCardDetails] = useState(isExpanded);

  const [selectedNote, setSelectedNote] = useState<AppointmentNote | null>(null);
  const [note, setNote] = useState("");
  const [visibleToMechanic, setVisibleToMechanic] = useState(false);
  const [date, setDate] = useState<Date | null>(null);
  const [phoneNr, setPhoneNr] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [attachments, setAttachments] = useState<Partial<AppointmentNoteAttachment>[] | null>(null);
  const [hasValidationError, setHasValidationError] = useState(false);
  const [noteType, setNoteType] = useState<number | null>(null);
  const canCreateNotes = useCan("create", ["appointment-notes", "appointment-notes-attachments", "appointment-notes-wo", "appointment-notes-parking-location"]);

  useEffect(() => {
    setShowCardDetails(isExpanded);
  }, [isExpanded]);

  const toggleCardDetails = () => {
    setShowCardDetails(prevState => !prevState);
  };

  const handleResetAppointmentNotesState = () => {
    setNote("");
    setVisibleToMechanic(false);
    setDate(null);
    setPhoneNr("");
    setName("");
    setEmail("");
    setAttachments(null);
    setHasValidationError(false);
  };

  useEffect(() => {
    const noteType = selectedNote?.appointment_note_type_id || 0;
    const note = selectedNote?.note || "";
    const visibleToMechanic = selectedNote?.visible_for_mechanic || false;
    const date = selectedNote?.backorder_date ? moment(selectedNote.backorder_date).toDate() : moment().toDate();
    const phoneNr = selectedNote?.phone_nr || "";
    const name = selectedNote?.name || "";
    const email = selectedNote?.email || "";
    const attachments = selectedNote?.attachments || null;

    setNoteType(noteType);
    setNote(note);
    setVisibleToMechanic(visibleToMechanic);
    setDate(date);
    setPhoneNr(phoneNr);
    setName(name);
    setEmail(email);
    setAttachments(attachments);
  }, [selectedNote]);

  useEffect(() => {
    if (!noteType) setNoteType(null);
    else if (
      [
        APPOINTMENT_NOTE_TYPES.BillNote,
        APPOINTMENT_NOTE_TYPES.Main,
        APPOINTMENT_NOTE_TYPES.BillNote,
        APPOINTMENT_NOTE_TYPES.RecurringCar,
        APPOINTMENT_NOTE_TYPES.CallCustomer,
        APPOINTMENT_NOTE_TYPES.Attachment,
        APPOINTMENT_NOTE_TYPES.Parking
      ].includes(noteType)
    ) {
      const note = appointment.notes?.find(note => note.appointment_note_type_id === noteType);

      if (note) setSelectedNote(note);
    }
  }, [noteType]);

  const handleConstructCreateNoteRequest = (type: APPOINTMENT_NOTE_TYPES) => {
    switch (type) {
      case APPOINTMENT_NOTE_TYPES.Info:
        return {
          path: "/appointments/new_status",
          payload: {
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.DiagnoseStatus,
            sa_remarks: note,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.BackOrder:
        return {
          path: "/appointments/new_status",
          payload: {
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.BackOrderStatus,
            sa_remarks: note,
            back_order_time_car_app: date,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.Wo:
        return { path: "/appointment_notes/create_wo_note", payload: { appointment_id: appointment.id, note, visible_for_mechanic: visibleToMechanic, attachments } };
      case APPOINTMENT_NOTE_TYPES.Main:
        return { path: "/appointment_notes/create_main_note", payload: { appointment_id: appointment.id, note, visible_for_mechanic: visibleToMechanic, attachments } };
      case APPOINTMENT_NOTE_TYPES.CallCustomer:
        return {
          path: "/appointment_notes/create_call_note",
          payload: { appointment_id: appointment.id, note, phone_nr: phoneNr, visible_for_mechanic: visibleToMechanic, attachments }
        };
      case APPOINTMENT_NOTE_TYPES.BillNote:
        return { path: "/appointment_notes/create_bill_note", payload: { appointment_id: appointment.id, note, attachment: attachments?.[0] } };
      case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
        return { path: "/appointment_notes/create_temp_driver_note", payload: { appointment_id: appointment.id, note, name, email, phone_nr: phoneNr, attachments } };
      case APPOINTMENT_NOTE_TYPES.RecurringCar:
        return { path: "/appointment_notes/create_recurring_car_note", payload: { appointment_id: appointment.id, note, attachments } };
      case APPOINTMENT_NOTE_TYPES.Attachment:
        return { path: "/appointment_notes/create_attachment_note", payload: { appointment_id: appointment.id, note, attachments } };
      case APPOINTMENT_NOTE_TYPES.Parking:
        return { path: "/appointment_notes/create_parking_note", payload: { appointment_id: appointment.id, note } };
      default:
        return null;
    }
  };

  const handleConstructUpdateNoteRequest = (type: APPOINTMENT_NOTE_TYPES) => {
    switch (type) {
      case APPOINTMENT_NOTE_TYPES.Info:
        return {
          path: "/appointments/new_status",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.DiagnoseStatus,
            sa_remarks: note,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.BackOrder:
        return {
          path: "/appointments/new_status",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            new_status_identifier: STATUS_IDENTIFIER.BackOrderStatus,
            sa_remarks: note,
            back_order_time_car_app: date,
            note_attachments: attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.Wo:
        return {
          path: "/appointment_notes/update_wo_note",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            visible_for_mechanic: visibleToMechanic,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.Main:
        return {
          path: "/appointment_notes/update_main_note",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            visible_for_mechanic: visibleToMechanic,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.CallCustomer:
        return {
          path: "/appointment_notes/update_call_note",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            phone_nr: phoneNr,
            visible_for_mechanic: visibleToMechanic,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.BillNote:
        return {
          path: "/appointment_notes/update_bill_note",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate(), attachment: attachments?.[0] }
        };
      case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
        return {
          path: "/appointment_notes/update_temp_driver_note",
          payload: {
            appointment_note_id: selectedNote?.id,
            appointment_id: appointment.id,
            note,
            name,
            email,
            phone_nr: phoneNr,
            updated_on: moment().toDate(),
            attachments
          }
        };
      case APPOINTMENT_NOTE_TYPES.RecurringCar:
        return {
          path: "/appointment_notes/update_recurring_car_note",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate(), attachments }
        };
      case APPOINTMENT_NOTE_TYPES.Attachment:
        return {
          path: "/appointment_notes/update_attachment_note",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate(), attachments }
        };
      case APPOINTMENT_NOTE_TYPES.Parking:
        return {
          path: "/appointment_notes/update_parking_note",
          payload: { appointment_note_id: selectedNote?.id, appointment_id: appointment.id, note, updated_on: moment().toDate() }
        };
      default:
        return null;
    }
  };

  const validateRequest = (type: number) => {
    switch (type) {
      case APPOINTMENT_NOTE_TYPES.Info:
      case APPOINTMENT_NOTE_TYPES.Wo:
      case APPOINTMENT_NOTE_TYPES.Main:
      case APPOINTMENT_NOTE_TYPES.RecurringCar:
      case APPOINTMENT_NOTE_TYPES.BackOrder:
      case APPOINTMENT_NOTE_TYPES.Parking:
        if (!note) {
          setHasValidationError(true);
          return false;
        }

        return true;

      case APPOINTMENT_NOTE_TYPES.CallCustomer:
      case APPOINTMENT_NOTE_TYPES.TemporaryDriverNote:
        if (!note || !phoneNr) {
          setHasValidationError(true);
          return false;
        }

        return true;
      case APPOINTMENT_NOTE_TYPES.BillNote:
        if (!attachments?.length) {
          setHasValidationError(true);
          return false;
        }

        return true;

      case APPOINTMENT_NOTE_TYPES.Attachment:
        if (!attachments?.length) {
          setHasValidationError(true);
          return false;
        }

        return true;

      default:
        setHasValidationError(false);
        return true;
    }
  };

  const handleCreateNote = (type: number | null) => {
    if (!type) return;

    const request = handleConstructCreateNoteRequest(type);

    if (!request) return;
    const { path, payload } = request;
    const isValid = validateRequest(type);

    const additionalFields = {
      appointment_note_type_id: noteType,
      created_on: moment().format("YYYY-MM-DD HH:mm:ssZ"),
      updated_on: moment().format("YYYY-MM-DD HH:mm:ssZ"),
      user_id: user?.id
    };

    if (isValid) {
      addAppointmentNote.mutate({ path, payload: { ...payload, ...additionalFields } });
      setNoteType(null);
      handleResetAppointmentNotesState();
    }
  };

  const handleUpdateNote = (type: number | null) => {
    if (!type) return;

    const request = handleConstructUpdateNoteRequest(type);

    if (!request) return;
    const { path, payload } = request;
    const isValid = validateRequest(type);

    if (isValid) {
      updateAppointmentNote.mutate({ path, payload: { ...payload } });
      setNoteType(null);
      setSelectedNote(null);
      handleResetAppointmentNotesState();
    }
  };

  const handleDeleteNote = (noteID: number) => {
    deleteAppointmentNote.mutate({ noteID });
    setSelectedNote(null);
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.name === "phoneNr") setPhoneNr(evt.target.value);
    if (evt.target.name === "name") setName(evt.target.value);
    if (evt.target.name === "email") setEmail(evt.target.value);
  };

  const handleTextAreaChange = (data: string) => {
    setNote(data);
  };

  const handleCheckboxChange = (checked: boolean | undefined) => {
    setVisibleToMechanic(!!checked);
  };

  const handleChangeDate = (date: Date | null) => {
    setDate(date);
  };

  const handleDropdownChange = (name: string, value: string | number | boolean | (string | number | boolean)[] | undefined) => {
    if (name === "phoneNr") {
      const [selectedCustomerPhone] = String(value).split(";");
      setPhoneNr(selectedCustomerPhone);
    }
  };

  const handleSelectNote = (note: AppointmentNote | null) => {
    setSelectedNote(note);
  };

  const handleUnselectNote = () => {
    setSelectedNote(null);
    setHasValidationError(false);
  };

  const handleModalClose = () => {
    setNoteType(null);
    setHasValidationError(false);
    handleResetAppointmentNotesState();
  };

  const handleUploadAttachment = async (file: File) => {
    if (!file) return;

    const extension = file.name.lastIndexOf(".") > -1 ? file.name.slice(file.name.lastIndexOf(".") + 1) : "unknown";
    const uploadFile = new File([file], file.name, { type: file.type || extension });
    const formData = new FormData();

    const date = moment(appointment.created_on).format("YYYY/MM/DD");
    const path = `${selectedLocation?.id}_${selectedLocation?.name}/${date}/${appointment.id}_${appointment.wo_nr}`;

    formData.append("file", uploadFile);
    formData.append("path", path);

    const attachmentUrl = await handleUploadAppointmentNoteAttachment({ formData });

    if (attachmentUrl) {
      const newFile = {
        url: attachmentUrl,
        type: file.type || extension,
        name: file.name,
        created_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
        updated_on: moment().utc().format("YYYY-MM-DDTHH:mm:ssZ"),
        username: `${user?.first_name || ""} ${user?.last_name || ""}`
      };

      if (noteType === APPOINTMENT_NOTE_TYPES.BillNote) {
        setAttachments([newFile]);
      } else {
        setAttachments(attach => (attach ? [...attach, newFile] : [newFile]));
      }
    }
  };

  const handleDeleteAttachment = (url: string) => {
    if (attachments?.length) setAttachments(attach => (attach ? attach.filter(attachment => attachment.url !== url) : null));
  };

  const handleFileChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files) {
      [...evt.target.files].forEach(file => {
        handleUploadAttachment(file);
      });
    }
  };

  const handleFileDragOver = (evt: React.DragEvent<HTMLInputElement>) => {
    evt.preventDefault();
  };

  const handleFileDrop = (evt: React.DragEvent<HTMLInputElement>) => {
    evt.preventDefault();

    [...evt.dataTransfer.files].forEach(file => {
      handleUploadAttachment(file);
    });
  };

  const { notes } = appointment;
  const dmsNotes = appointment.dms_notes || [];
  const dmsCarNotes = appointment.car?.dms_notes || [];

  const dmsAppointmentAndCarNotesLength = (dmsNotes?.length || 0) + (dmsCarNotes?.length || 0);

  const importantNotes = notes?.filter(note => [APPOINTMENT_NOTE_TYPES.Main, APPOINTMENT_NOTE_TYPES.CallCustomer].includes(note.appointment_note_type_id));
  const receptionistRemarks = notes?.filter(note => ![APPOINTMENT_NOTE_TYPES.Main, APPOINTMENT_NOTE_TYPES.CallCustomer].includes(note.appointment_note_type_id));

  if (noteTypeOptions.length <= 0) return null;

  return (
    <DetailsCards
      id={tabData.id}
      title={`${t("notes").message || "Notes"} ${tabData.data.length > 0 ? `(${tabData.data.length})` : ""}`}
      icon="note sticky"
      showExpandArrow={false}
      rightContent={
        appointment?.appointment_status_identifier !== STATUS_IDENTIFIER.CanceledStatus && canCreateNotes ? (
          <>
            <Icon className={`chevron ${showCardDetails ? "down" : "right"} pointer`} onClick={toggleCardDetails} />
            <Dropdown
              icon={null}
              trigger={
                <div className="add-note-container">
                  <Icon className="plus" color="green" />
                </div>
              }
              selectOnBlur={false}
              value={noteType as number}
              options={noteTypeOptions.map(option => ({
                ...option,
                icon: () => {
                  if (option?.value === APPOINTMENT_NOTE_TYPES.BackOrder) return <div className="custom-back-order-dropdown-icon">BO</div>;
                  return <AppointmentNoteTypeIcon type={option?.value} />;
                }
              }))}
              onChange={(_, { value }) => setNoteType(value as number)}
            />
          </>
        ) : null
      }
    >
      <div className="AppointmentNotes">
        <div className="appointment-notes-content">
          {!!importantNotes?.length &&
            importantNotes.map(note => {
              return <AppointmentNoteRow appointmentNote={note} onSelectNote={() => handleSelectNote(note)} key={note.id} />;
            })}

          {!!receptionistRemarks?.length && showCardDetails && (
            <div className="receptionist-remarks-container">
              {receptionistRemarks.map(note => {
                return <AppointmentNoteRow appointmentNote={note} onSelectNote={() => handleSelectNote(note)} key={note.id} />;
              })}
            </div>
          )}

          {dmsAppointmentAndCarNotesLength > 0 && showCardDetails && (
            <div className="dms-remarks-container">
              <DMSNotes dmsNotes={dmsNotes} dmsCarNotes={dmsCarNotes} />
            </div>
          )}
        </div>

        {(!!noteType || !!selectedNote) && (
          <AddUpdateAppointmentNote
            note={note}
            noteType={noteType}
            visibleToMechanic={visibleToMechanic}
            phoneNr={phoneNr}
            name={name}
            email={email}
            date={date}
            attachments={attachments}
            selectedNote={selectedNote}
            hasValidationError={hasValidationError}
            phoneOptions={phoneOptions}
            onInputChange={handleInputChange}
            onTextAreaChange={handleTextAreaChange}
            onCheckBoxChange={handleCheckboxChange}
            onChangeDate={handleChangeDate}
            onDropdownChange={handleDropdownChange}
            onCloseAddUpdateNoteModal={handleModalClose}
            onUnselectNote={handleUnselectNote}
            onFileInputChange={handleFileChange}
            onFileDragOver={handleFileDragOver}
            onFileDrop={handleFileDrop}
            onDeleteAttachment={handleDeleteAttachment}
            onCreateNote={handleCreateNote}
            onUpdateNote={handleUpdateNote}
            onDeleteNote={handleDeleteNote}
            isCreatingAppointmentNote={addAppointmentNote.isPending}
            isUpdatingAppointmentNote={updateAppointmentNote.isPending}
            isDeletingAppointmentNote={deleteAppointmentNote.isPending}
          />
        )}
      </div>
    </DetailsCards>
  );
};
