import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import ENV from "config/Env";
import { Appointment, Intervention, QuestionResult } from "models";
import ApiInstance from "util/Api";
import { queryKeys } from "util/keyFactory";

interface AdditionalSnoozeData {
  id?: number;
  user?: {
    first_name: string;
    last_name: string;
  };
}

interface AddSnooze extends AdditionalSnoozeData {
  note: string;
  snooze_type_id: number;
  snooze_date: string;
  snooze_status_id: number;
  snooze_department_id: number;
  appointment_id: number;
  appointment: Appointment | null;
  created_on: string;
  question_result_id: number;
  question_result: QuestionResult | null;
  intervention: Intervention | null;
}

export const useSnooze = (id: string) => {
  const queryClient = useQueryClient();

  const addSnoozeFn = async (data: AddSnooze, updateData?: AdditionalSnoozeData) => {
    const postData: AddSnooze = {
      ...data
    };
    if (updateData) {
      postData.id = updateData.id;
      postData.user = updateData.user;
    }
    const res = await ApiInstance.post("/snoozes/append", postData, ENV.appointmentBaseURL);
    return res.data;
  };

  const addSnooze = useMutation({
    mutationFn: addSnoozeFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "realtime",
          {
            ...queryKeys.appointmentDetails.checklist,
            params: { appointment_id: id, embed: "pin,snooze" }
          }
        ]
      });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  const handleDeleteSnooze = async ({ question_result_id, note }: { question_result_id: number; note: string }) => {
    const res = await ApiInstance.post("/snoozes/delete", { question_result_id, note }, ENV.appointmentBaseURL);
    return res.data;
  };

  const deleteSnooze = useMutation({
    mutationFn: handleDeleteSnooze,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "realtime",
          {
            ...queryKeys.appointmentDetails.checklist,
            params: { appointment_id: id, embed: "pin,snooze" }
          }
        ]
      });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  return { addSnooze, deleteSnooze };
};
