import { useMutation, useQueryClient } from "@tanstack/react-query";

import ENV from "config/Env";
import { useAppointmentData } from "modules/AppointmentDetails/hooks";
import apiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export function useCustomerActions(appointment_id: number) {
  const queryClient = useQueryClient();
  const appointment = useAppointmentData(String(appointment_id)).data;

  const appointmentQueryKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointment_id) }
    }
  ];

  const { mutate: updateCheckInRemark, isPending: isUpdatingCheckInRemark } = useMutation({
    mutationFn: async ({ checkin_remark_id, handled }) => {
      await apiInstance.post(`/check_in/remark/handle`, { checkin_remark_id, handled }, ENV.appointmentBaseURL);
    },
    onMutate: ({ checkin_remark_id, handled }: { checkin_remark_id: number; handled: boolean }) => {
      if (!appointment?.customer_communication) return;
      const clonedCustomerCommuncation = structuredClone(appointment.customer_communication);

      const matchingCheckInRemark = clonedCustomerCommuncation?.check_in_remarks?.find(remark => remark.id === checkin_remark_id);
      if (!matchingCheckInRemark) return;
      matchingCheckInRemark!.receptionist_handled = handled;

      queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication: clonedCustomerCommuncation });
      return appointment;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(appointmentQueryKey, context);
    }
  });

  const { mutate: updateCheckInResult, isPending: isUpdatingCheckInResult } = useMutation({
    mutationFn: async ({ checkin_result_id, handled }) => {
      const response = await apiInstance.post(`/check_in/result/handle`, { checkin_result_id, handled }, ENV.appointmentBaseURL);
      return response;
    },
    onMutate: ({ checkin_result_id, handled }: { checkin_result_id: number; handled: boolean }) => {
      if (!appointment?.customer_communication) return;
      const clonedCustomerCommuncation = structuredClone(appointment?.customer_communication);

      const matchingCheckInResult = clonedCustomerCommuncation?.check_in_results?.find(result => result.id === checkin_result_id);
      if (!matchingCheckInResult) return;
      matchingCheckInResult.receptionist_handled = handled;

      queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication: clonedCustomerCommuncation });
      return appointment;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(appointmentQueryKey, context);
    }
  });

  const { mutate: updateDiagnoseOverviewRemark, isPending: isUpdatingDiagnoseOverviewRemark } = useMutation({
    mutationFn: async ({ diagnose_overview_remark_id, handled_remark }) => {
      const response = await apiInstance.post(`/diagnose_overview/remark/handle`, { diagnose_overview_remark_id, handled_remark }, ENV.appointmentBaseURL);
      return response;
    },
    onMutate: ({ diagnose_overview_remark_id, handled_remark }: { diagnose_overview_remark_id: number; handled_remark: boolean }) => {
      if (!appointment?.customer_communication) return;
      const clonedCustomerCommunication = structuredClone(appointment.customer_communication);

      const matchingDiagnoseOverviewRemark = clonedCustomerCommunication.diagnose_overview_remarks?.find(remark => remark.id === diagnose_overview_remark_id);
      if (!matchingDiagnoseOverviewRemark) return;
      matchingDiagnoseOverviewRemark.receptionist_handled = handled_remark;

      queryClient.setQueryData(appointmentQueryKey, { ...appointment, customer_communication: clonedCustomerCommunication });
      return appointment;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(appointmentQueryKey, context);
    }
  });

  const { mutate: updateKeylockerRemarks, isPending: isUpdatingKeylockerRamarks } = useMutation({
    mutationFn: async ({ keylocker_remark_id, handled_remark }) => {
      const response = await apiInstance.post(`/dashboard/remark/handle`, { keylocker_remark_id, handled_remark }, ENV.keylockerBaseURL);
      return response;
    },
    onMutate: ({ keylocker_remark_id, handled_remark }: { keylocker_remark_id: number; handled_remark: boolean }) => {
      if (!appointment?.keylocker_communications) return;
      const clonedKeylockerCommunications = structuredClone(appointment.keylocker_communications);

      const matchingKeylockerRemark = clonedKeylockerCommunications.find(remark => remark.id === keylocker_remark_id)?.remark;
      if (!matchingKeylockerRemark) return;
      matchingKeylockerRemark.receptionist_handled = handled_remark;

      queryClient.setQueryData(appointmentQueryKey, { ...appointment, keylocker_communications: clonedKeylockerCommunications });
      return appointment;
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(appointmentQueryKey, context);
    }
  });

  return {
    updateCheckInRemark,
    isUpdatingCheckInRemark,
    updateCheckInResult,
    isUpdatingCheckInResult,
    updateDiagnoseOverviewRemark,
    isUpdatingDiagnoseOverviewRemark,
    updateKeylockerRemarks,
    isUpdatingKeylockerRamarks
  };
}
