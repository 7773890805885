import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, DropdownProps, Icon, Label } from "semantic-ui-react";

import { NavBarSearch, NavBarSearchAppointments, NavPortal, useCan } from "components";
import { useSearchTerm } from "components/NavBarSearch/useSearchTerm";
import { useDealersLocations } from "hooks";
import { Appointment as AppointmentModel } from "models";
import { DAYPLANNER_STATUSES, ShouldBeFiltered, StatusFilters, filtersMatchingStatuses } from "modules/Dayplanner/components";
import { AppointmentCard, MechanicBox } from "modules/Dayplanner/components";
import "modules/Dayplanner/Dayplanner.scss";
import { useAssignMutation, useDayplannerData, useReassignMutation, useUnassignMutation } from "modules/Dayplanner/hooks";
import AscImg from "modules/Dayplanner/img/asc.png";
import DescImg from "modules/Dayplanner/img/desc.png";
import { DayplannerColumnsInterface, MechanicWithAppointments, ascendingSort, descendingSort, prepareDayplannerData, sortAppointments } from "modules/Dayplanner/utils";
import { getPreference, setPreference } from "util/common";

export enum DAYPLANNER_COLUMNS {
  Appointments = 1,
  Unassigned,
  Mechanics,
  CarReady,
  QualityCheck
}

export enum MECHANIC_FILTERS {
  All = 1,
  HideOccupiedMechanics,
  HideUnoccupiedMechanics
}

export enum WO_INTERNAL_FILTER {
  All = 1,
  WO,
  Internal
}

export enum MECHANIC_SORTING {
  Ascending = "ascending",
  Descending = "descending"
}

interface Appointment extends AppointmentModel {
  inactive?: boolean;
}

const initialState: DayplannerColumnsInterface = {
  appointmentsColumn: [],
  unassignedColumn: [],
  mechanicsColumn: [],
  carReadyColumn: [],
  qualityControlColumn: []
};

const activeFiltersInitialState = [
  DAYPLANNER_STATUSES.NewCar,
  DAYPLANNER_STATUSES.CarInShop,
  DAYPLANNER_STATUSES.Panic,
  DAYPLANNER_STATUSES.InitialCheckDone,
  DAYPLANNER_STATUSES.HandleCheckin,
  DAYPLANNER_STATUSES.CarCheck,
  DAYPLANNER_STATUSES.CustomerAnswered,
  DAYPLANNER_STATUSES.CustomerOK,
  DAYPLANNER_STATUSES.Diagnose
];

interface DayplannerContextProps {
  columns: DayplannerColumnsInterface;
  onDragAppointmentStart: (e: React.DragEvent<HTMLDivElement>, appointment: Appointment, mechanic?: MechanicWithAppointments) => void;
  onDropAppointmentToMechanicPlaceholder: (e: React.DragEvent<HTMLDivElement>) => void;
  onDraggedAppointmentEnterMechanicPlaceholder: (e: React.DragEvent<HTMLDivElement>) => void;
  onDraggedAppointmentLeaveMechanicPlaceholder: (e: React.DragEvent<HTMLDivElement>) => void;
  onDraggedAppointmentEnterAnotherAppointment: (e: React.DragEvent<HTMLDivElement>) => void;
}

const DayplannerContext = createContext<DayplannerContextProps | undefined>(undefined);

enum PREFERENCE_KEYS {
  MECHANIC_SORTING = "preferences-dayplanner-mechanics-sorting",
  PINNED_MECHANICS = "preferences-dayplanner-pinned-mechanics",
  SELECTED_MECHANIC = "preferences-dayplanner-selected-mechanic-filter",
  SELECTED_WO_INTERNAL = "preferences-dayplanner-selected-WOInternal-filter",
  SELECTED_ACTIVE_FILTER = "preferences-dayplanner-selected-status-filter",
  COLLAPSED_COLUMNS = "preferences-dayplanner-collapsed-columns"
}

export const useDayplannerContext = () => {
  const context = useContext(DayplannerContext);
  if (!context) {
    throw new Error("useDayplannerContext must be used within a DayplannerContext.Provider");
  }
  return context;
};

const Dayplanner = ({ t }: any) => {
  const { mechanics, appointments, loading, error } = useDayplannerData();
  const [columns, setColumns] = useState(initialState);
  const [defaultColumns, setDefaultColumns] = useState(initialState);
  const [preparedAppointments, setPreparedAppointments] = useState<Appointment[]>([]);
  const [collapsedColumns, setCollapsedColumns] = useState<DAYPLANNER_COLUMNS[]>(getPreference(PREFERENCE_KEYS.COLLAPSED_COLUMNS, []));
  const [numberOfCardShownInMechanicColumn, setNumberOfCardShownInMechanicColumn] = useState(0);
  const [mechanicsFilter, setMechanicsFilter] = useState<MECHANIC_FILTERS>(getPreference(PREFERENCE_KEYS.SELECTED_MECHANIC, MECHANIC_FILTERS.All));
  const [mechanicsRefNode, setMechanicsRefNode] = useState<HTMLDivElement | null>(null);
  const [selectedWoInternalFilter, setSelectedWoInternalFilter] = useState<WO_INTERNAL_FILTER>(
    getPreference(PREFERENCE_KEYS.SELECTED_WO_INTERNAL, WO_INTERNAL_FILTER.All)
  );

  const [draggedAppointment, setDraggedAppointment] = useState<Appointment | null>(null);
  const [draggedFromMechanic, setDraggedFromMechanic] = useState<MechanicWithAppointments | null>(null);
  const [activeFilters, setActiveFilters] = useState<DAYPLANNER_STATUSES[]>(getPreference(PREFERENCE_KEYS.SELECTED_ACTIVE_FILTER, activeFiltersInitialState));
  const [expandedMechanics, setExpandedMechanics] = useState<Set<number>>(new Set());
  const [mechanicsPinned, setMechanicsPinned] = useState<number[]>(getPreference(PREFERENCE_KEYS.PINNED_MECHANICS, []));
  const [mechanicsSorting, setMechanicsSorting] = useState<MECHANIC_SORTING>(getPreference(PREFERENCE_KEYS.MECHANIC_SORTING, MECHANIC_SORTING.Ascending));
  const [didApplyFilters, setDidApplyFilters] = useState(false);

  const { selectedLocation } = useDealersLocations();
  const searchTerm = useSearchTerm();

  const reassignAppointments = useReassignMutation();
  const assignAppointments = useAssignMutation();
  const unassignAppointments = useUnassignMutation();

  const appointmentsColumnRef = useRef<HTMLDivElement>(null);
  const unassignedColumnRef = useRef<HTMLDivElement>(null);
  const carReadyColumnRef = useRef<HTMLDivElement>(null);
  const qualityControlColumnRef = useRef<HTMLDivElement>(null);
  const canAssignWo = useCan("assign", "dayplanner");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);

  useEffect(() => {
    if (!loading && mechanics && appointments) {
      const { columns: preparedData, dayplannerAppointments } = prepareDayplannerData(mechanics, appointments, mechanicsSorting, mechanicsPinned);
      setColumns(preparedData);
      setDefaultColumns(preparedData);
      setPreparedAppointments(dayplannerAppointments);
    }
  }, [mechanics, appointments, loading, mechanicsSorting, mechanicsPinned]);

  const mechanicsColumnRef = useCallback((node: HTMLDivElement) => {
    if (node) {
      setMechanicsRefNode(node);
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        const MARGIN_VALUE = 30;
        const CARD_WIDTH = 180;
        const numberOfCards = Math.floor(width / (CARD_WIDTH + MARGIN_VALUE));

        setNumberOfCardShownInMechanicColumn(numberOfCards);
      }
    });

    if (mechanicsRefNode) {
      resizeObserver.observe(mechanicsRefNode);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [mechanicsRefNode]);

  const handleSelectMechanicSorting = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as MECHANIC_SORTING;
    const newMechanicsColumn = [...columns.mechanicsColumn];
    if (value === MECHANIC_SORTING.Ascending) newMechanicsColumn.sort(ascendingSort);
    else newMechanicsColumn.sort(descendingSort);

    setColumns((prevColumns: DayplannerColumnsInterface) => ({
      ...prevColumns,
      mechanicsColumn: newMechanicsColumn
    }));

    setMechanicsSorting(value);
    setPreference(PREFERENCE_KEYS.MECHANIC_SORTING, value);
  };

  const handleToggleMechanicPin = (mechanic_id: number) => {
    const mechanicPinnedCopy = [...mechanicsPinned];
    const mechanicIndex = mechanicPinnedCopy.indexOf(mechanic_id);
    if (mechanicIndex === -1) {
      mechanicPinnedCopy.push(mechanic_id);
    } else {
      mechanicPinnedCopy.splice(mechanicIndex, 1);
    }
    setMechanicsPinned(mechanicPinnedCopy);
    setPreference(PREFERENCE_KEYS.PINNED_MECHANICS, mechanicPinnedCopy);
  };

  const onDragAppointmentEnd = () => {
    appointmentsColumnRef.current?.classList.remove("-column-not-allowed-to-drop");
    carReadyColumnRef.current?.classList.remove("-column-not-allowed-to-drop");
    qualityControlColumnRef.current?.classList.remove("-column-not-allowed-to-drop");
  };

  const cancelDragAppointmentFromAssignedMechanic = (draggedFromMechanic: MechanicWithAppointments) => {
    const newMechanics = columns.mechanicsColumn.map(mechanic => (mechanic.id === draggedFromMechanic.id ? draggedFromMechanic : mechanic));

    setDraggedAppointment(null);
    setDraggedFromMechanic(null);
    setColumns(prevState => ({ ...prevState, mechanicsColumn: newMechanics }));
  };

  const cancelDragAppointmentFromUnassignedColumn = (draggedAppointment: Appointment) => {
    const newUnassignedColumn = [...columns.unassignedColumn];

    newUnassignedColumn.push(draggedAppointment);
    sortAppointments(newUnassignedColumn);

    setColumns(prevState => ({ ...prevState, unassignedColumn: newUnassignedColumn }));
    setDraggedAppointment(null);
    setDraggedFromMechanic(null);
  };

  const cancelDragAppointment = (e?: DragEvent) => {
    e?.preventDefault();
    if (!draggedAppointment) return;

    if (draggedFromMechanic) cancelDragAppointmentFromAssignedMechanic(draggedFromMechanic);
    else cancelDragAppointmentFromUnassignedColumn(draggedAppointment);

    onDragAppointmentEnd();
  };

  const cancelDragAppointmentIfOutOfScreen = (e: DragEvent) => {
    const nearViewportEdges = e.clientX <= 0 || e.clientY <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight;

    if (nearViewportEdges) {
      cancelDragAppointment(e);
    }
  };
  const woInternalFiltered = (app: Appointment) => {
    return selectedWoInternalFilter === WO_INTERNAL_FILTER.Internal ? app.internal : !app.internal;
  };
  const applyFilters = () => {
    const appointmentMatchesSearch = (app: Appointment) => {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      return (
        app.wo_nr.toLowerCase().includes(lowerCaseSearchTerm) ||
        app.reg_number.toLowerCase().includes(lowerCaseSearchTerm) ||
        `${app.driver_firstname} ${app.driver_surname}`.toLowerCase().includes(lowerCaseSearchTerm) ||
        `${app.owner_firstname} ${app.owner_surname}`.toLowerCase().includes(lowerCaseSearchTerm)
      );
    };

    const matchFilterAndSearch = (appointment: Appointment) => {
      const matchesFilter = activeFilters.some(filter => ShouldBeFiltered(appointment, filter));
      const matchesSearch = searchTerm ? appointmentMatchesSearch(appointment) : true;
      const matchesWoInternalFilter = selectedWoInternalFilter !== WO_INTERNAL_FILTER.All ? woInternalFiltered(appointment) : true;
      const isMatchingStatus = filtersMatchingStatuses.includes(appointment.appointment_status_identifier);
      const isInactiveMatchingStatus = !(matchesFilter && matchesSearch && matchesWoInternalFilter && isMatchingStatus);
      const isInactiveNonMatchingStatus = !(matchesWoInternalFilter && matchesSearch);

      return {
        ...appointment,
        inactive: isMatchingStatus ? isInactiveMatchingStatus : isInactiveNonMatchingStatus
      };
    };

    const updatedColumns = {
      appointmentsColumn: defaultColumns.appointmentsColumn.map(matchFilterAndSearch),
      unassignedColumn: defaultColumns.unassignedColumn.map(matchFilterAndSearch),
      mechanicsColumn: defaultColumns.mechanicsColumn.map((mechanic: MechanicWithAppointments) => ({
        ...mechanic,
        appointments: mechanic.appointments.map(matchFilterAndSearch)
      })),
      carReadyColumn: defaultColumns.carReadyColumn.map(matchFilterAndSearch),
      qualityControlColumn: defaultColumns.qualityControlColumn.map(matchFilterAndSearch)
    };
    setColumns((prevState: any) => ({
      ...prevState,
      ...updatedColumns
    }));

    setDidApplyFilters(true);
  };

  useEffect(() => {
    if (loading) return;
    applyFilters();
  }, [searchTerm, activeFilters, defaultColumns, selectedWoInternalFilter, loading]);

  useEffect(() => {
    const preventDragDefault = (e: DragEvent) => e.preventDefault();

    document.addEventListener("dragenter", preventDragDefault);
    document.addEventListener("dragover", preventDragDefault);
    document.addEventListener("dragleave", cancelDragAppointmentIfOutOfScreen);
    document.addEventListener("drop", cancelDragAppointment);

    return () => {
      document.removeEventListener("dragenter", preventDragDefault);
      document.removeEventListener("dragover", preventDragDefault);
      document.removeEventListener("dragleave", cancelDragAppointmentIfOutOfScreen);
      document.removeEventListener("drop", cancelDragAppointment);
    };
  }, [draggedAppointment, draggedFromMechanic]);

  const handleToggleCollapseColumn = (column: DAYPLANNER_COLUMNS) => {
    const newCollapsedColumns: DAYPLANNER_COLUMNS[] = collapsedColumns.includes(column) ? collapsedColumns.filter(col => col !== column) : [...collapsedColumns, column];
    setCollapsedColumns(newCollapsedColumns);
    setPreference(PREFERENCE_KEYS.COLLAPSED_COLUMNS, newCollapsedColumns);
  };

  const handleToggleExpandMechanic = (mechanicId: number) => {
    setExpandedMechanics(prevExpandedMechanics => {
      const newExpandedMechanics = new Set(prevExpandedMechanics);
      if (newExpandedMechanics.has(mechanicId)) {
        newExpandedMechanics.delete(mechanicId);
      } else {
        newExpandedMechanics.add(mechanicId);
      }
      return newExpandedMechanics;
    });
  };

  const handleSelectMechanicFilter = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as MECHANIC_FILTERS;

    setMechanicsFilter(value);
    setPreference(PREFERENCE_KEYS.SELECTED_MECHANIC, value);
  };

  const handleWoInternalFilter = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = data.value as WO_INTERNAL_FILTER;

    setSelectedWoInternalFilter(value);
    setPreference(PREFERENCE_KEYS.SELECTED_WO_INTERNAL, value);
  };

  const handleSelectStatusFilter = (selectedFilter: DAYPLANNER_STATUSES) => {
    const selectedFilters = activeFilters.includes(selectedFilter) ? activeFilters.filter(filter => filter !== selectedFilter) : [...activeFilters, selectedFilter];
    setActiveFilters(selectedFilters);
    setPreference(PREFERENCE_KEYS.SELECTED_ACTIVE_FILTER, selectedFilters);
  };

  const onDragAppointmentStart = (_e: React.DragEvent<HTMLDivElement>, appointment: Appointment, mechanic?: MechanicWithAppointments) => {
    if (!canAssignWo) return;

    appointmentsColumnRef.current?.classList.add("-column-not-allowed-to-drop");
    carReadyColumnRef.current?.classList.add("-column-not-allowed-to-drop");
    qualityControlColumnRef.current?.classList.add("-column-not-allowed-to-drop");

    if (appointment.assigned_mechanic && mechanic) {
      const newMechanics = columns.mechanicsColumn.map((m: MechanicWithAppointments) => {
        if (m.id === mechanic.id) {
          const updatedAppointments = m.appointments.reduce((newApps: Appointment[], app: Appointment) => {
            if (app.assigned_mechanic_order && appointment.assigned_mechanic_order && app.assigned_mechanic_order > appointment.assigned_mechanic_order) {
              return [...newApps, { ...app, assigned_mechanic_order: app.assigned_mechanic_order - 1 }] as Appointment[];
            }

            if (app.id !== appointment.id) {
              return [...newApps, app] as Appointment[];
            }

            return newApps;
          }, []);
          return { ...m, appointments: updatedAppointments } as MechanicWithAppointments;
        }

        return m;
      });

      window.requestAnimationFrame(() => {
        setDraggedAppointment(appointment);
        setDraggedFromMechanic(mechanic);
        setColumns(prevState => ({ ...prevState, mechanicsColumn: newMechanics }));
      });
    } else {
      const newUnassignedColumn = columns.unassignedColumn.filter((app: Appointment) => app.id !== appointment.id);

      window.requestAnimationFrame(() => {
        setDraggedAppointment(appointment);
        setDraggedFromMechanic(null);
        setColumns(prevState => ({ ...prevState, unassignedColumn: newUnassignedColumn }));
      });
    }
  };

  const shiftMechanicAppointments = (destMechanicOrder: number, destMechanicId: number) => {
    return new Promise<void>(resolve => {
      let destMechanic = { ...columns.mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) };

      if (destMechanic.appointments && destMechanic?.appointments.length > 9) return;

      destMechanic = {
        ...destMechanic,
        appointments: destMechanic.appointments?.map(app => {
          return app?.assigned_mechanic_order != null && app.assigned_mechanic_order >= destMechanicOrder
            ? { ...app, assigned_mechanic_order: app.assigned_mechanic_order + 1 }
            : app;
        })
      } as MechanicWithAppointments;

      const newMechanics = columns.mechanicsColumn.map(mechanic => (mechanic.id === destMechanic.id ? destMechanic : mechanic)) as MechanicWithAppointments[];
      const newDefaultMechanics = defaultColumns.mechanicsColumn.map(mechanic =>
        mechanic.id === destMechanic.id ? destMechanic : mechanic
      ) as MechanicWithAppointments[];

      setColumns(prevState => ({ ...prevState, mechanicsColumn: newMechanics }));
      setDefaultColumns(prevState => ({ ...prevState, mechanicsColumn: newDefaultMechanics }));
      resolve();
    });
  };

  const unshiftMechanicAppointments = (destMechanicId: number, destMechanicOrder: number) => {
    return new Promise<void>(resolve => {
      if (!draggedAppointment || !destMechanicId) return;

      let destMechanic = { ...columns.mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) };
      const hasHigherOrder = destMechanic?.appointments?.some(app => app.assigned_mechanic_order! > destMechanicOrder);

      if (!hasHigherOrder) return;

      destMechanic = {
        ...destMechanic,
        appointments: destMechanic?.appointments?.map(app => {
          if (app.assigned_mechanic_order && app.assigned_mechanic_order > destMechanicOrder) return { ...app, assigned_mechanic_order: app.assigned_mechanic_order - 1 };

          return app;
        }) as Appointment[]
      };

      const newMechanics = columns.mechanicsColumn.map(mechanic => (mechanic.id === destMechanic.id ? destMechanic : mechanic)) as MechanicWithAppointments[];
      const newDefaultMechanics = defaultColumns.mechanicsColumn.map(mechanic =>
        mechanic.id === destMechanic.id ? destMechanic : mechanic
      ) as MechanicWithAppointments[];

      setColumns(prevState => ({ ...prevState, mechanicsColumn: newMechanics }));
      setDefaultColumns(prevState => ({ ...prevState, mechanicsColumn: newDefaultMechanics }));
      resolve();
    });
  };

  const onDraggedAppointmentEnterMechanicPlaceholder = (e: React.DragEvent<HTMLDivElement>) => {
    if (!canAssignWo) return;

    e.preventDefault();
    e.currentTarget.classList.add("-on-drag-hover");
  };

  const onDraggedAppointmentLeaveMechanicPlaceholder = async (e: React.DragEvent<HTMLDivElement>) => {
    if (!canAssignWo) return;

    e.preventDefault();
    e.currentTarget.classList.remove("-on-drag-hover");

    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);
    const destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);

    await unshiftMechanicAppointments(destMechanicId, destMechanicOrder);
  };

  const onReassignAppointmentToMechanicPlaceholder = (e: React.DragEvent<HTMLDivElement>) => {
    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);
    let destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);
    let newMechanic = { ...columns.mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) } as MechanicWithAppointments;
    const newDraggedAppointment = { ...draggedAppointment };

    if (!newDraggedAppointment.id || !newMechanic.id) return;

    if (!newMechanic.appointments || (newMechanic.appointments && newMechanic.appointments.length > 9)) return;

    if (destMechanicOrder > 1 && newMechanic.appointments.length < destMechanicOrder - 1) destMechanicOrder -= destMechanicOrder - 1 - newMechanic.appointments.length;

    if (newMechanic.id === draggedFromMechanic?.id && draggedAppointment?.assigned_mechanic_order === destMechanicOrder) {
      cancelDragAppointment();
      return;
    }

    newDraggedAppointment.assigned_mechanic = newMechanic.id;
    newDraggedAppointment.assigned_mechanic_order = destMechanicOrder;

    newMechanic = { ...newMechanic, appointments: [...newMechanic.appointments, { ...newDraggedAppointment } as Appointment] } as MechanicWithAppointments;
    const newMechanics = columns.mechanicsColumn.map(mechanic => (mechanic.id === newMechanic.id ? newMechanic : mechanic)) as MechanicWithAppointments[];

    let newDefaultMechanics: MechanicWithAppointments[];
    if (newMechanic.id === draggedFromMechanic?.id) {
      newDefaultMechanics = defaultColumns.mechanicsColumn.map(m => (m.id === draggedFromMechanic?.id ? newMechanic : m)) as MechanicWithAppointments[];
    } else {
      const sourceMechanic = columns.mechanicsColumn.find(m => m.id === draggedFromMechanic?.id);
      newDefaultMechanics = columns.mechanicsColumn.map(m => {
        if (m.id === draggedFromMechanic?.id) return sourceMechanic;
        if (m.id === destMechanicId) return newMechanic;
        return m;
      }) as MechanicWithAppointments[];
    }

    setDraggedAppointment(null);
    setDraggedFromMechanic(null);
    setColumns(prevState => ({ ...prevState, mechanicsColumn: newMechanics }));
    setDefaultColumns(prevState => ({ ...prevState, mechanicsColumn: newDefaultMechanics }));

    reassignAppointments({ appointment_id: newDraggedAppointment.id, user_id: newMechanic.id, order: destMechanicOrder });
  };

  const onAssignAppointmentToMechanicPlaceholder = (e: React.DragEvent<HTMLDivElement>) => {
    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);
    let destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);
    const mechanic = { ...columns.mechanicsColumn.find(mechanic => mechanic.id === destMechanicId) } as MechanicWithAppointments;

    if (!draggedAppointment?.id || !mechanic?.id) return;
    if (!mechanic.appointments || (mechanic.appointments && mechanic.appointments.length > 9)) return;
    if (destMechanicOrder > 1 && mechanic.appointments.length < destMechanicOrder - 1) destMechanicOrder -= destMechanicOrder - 1 - mechanic.appointments.length;

    setDraggedAppointment(null);
    setDraggedFromMechanic(null);

    assignAppointments({ appointment_id: draggedAppointment.id, user_id: mechanic.id, order: destMechanicOrder });
  };

  const onDropAppointmentToMechanicPlaceholder = (e: React.DragEvent<HTMLDivElement>) => {
    if (!canAssignWo) return;

    e.stopPropagation();
    e.preventDefault();

    if (!draggedAppointment) return;

    if (draggedFromMechanic) onReassignAppointmentToMechanicPlaceholder(e);
    else onAssignAppointmentToMechanicPlaceholder(e);

    e.currentTarget.classList.remove("-on-drag-hover");

    onDragAppointmentEnd();
  };

  const onDraggedAppointmentEnterAnotherAppointment = async (e: React.DragEvent<HTMLDivElement>) => {
    if (!canAssignWo) return;

    e.preventDefault();

    const destMechanicId = Number(e.currentTarget.dataset.mechanicId);
    const destMechanicOrder = Number(e.currentTarget.dataset.mechanicOrder);

    if (!draggedAppointment) return;

    const destMechanicIndex = columns.mechanicsColumn.findIndex(mechanic => mechanic.id === destMechanicId);
    if (destMechanicIndex === -1) return;

    const destMechanic = columns.mechanicsColumn[destMechanicIndex];

    if (!expandedMechanics.has(destMechanic.id) && !destMechanic.auto_expand && destMechanic.appointments.length >= numberOfCardShownInMechanicColumn) {
      const newMechanicsColumn = columns.mechanicsColumn.map(mechanic =>
        mechanic.id === destMechanicId ? { ...mechanic, auto_expand: true } : mechanic
      ) as MechanicWithAppointments[];
      const newDefaultMechanicsColumn = defaultColumns.mechanicsColumn.map(mechanic =>
        mechanic.id === destMechanicId ? { ...mechanic, auto_expand: true } : mechanic
      ) as MechanicWithAppointments[];
      setColumns(prevState => ({ ...prevState, mechanicsColumn: newMechanicsColumn }));
      setDefaultColumns(prevState => ({ ...prevState, mechanicsColumn: newDefaultMechanicsColumn }));
    } else if (destMechanicId) {
      await shiftMechanicAppointments(destMechanicOrder, destMechanicId);
    } else {
      e.currentTarget.classList.add("-on-drag-hover");
    }
  };

  const onDropAppointmentToUnassignColumn = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();

    if (!draggedAppointment) return;

    if (!draggedFromMechanic) {
      cancelDragAppointment();
      return;
    }

    const newDraggedAppointment = { ...draggedAppointment };

    newDraggedAppointment.assigned_mechanic = null;
    newDraggedAppointment.assigned_mechanic_order = 0;

    setDraggedAppointment(null);
    setDraggedFromMechanic(null);

    unassignAppointments({ appointment_id: newDraggedAppointment.id });

    e.currentTarget.classList.remove("-on-drag-hover");
    onDragAppointmentEnd();
  };

  const onDraggedAppointmentEnterUnassignColumn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.classList.add("-on-drag-hover");
  };

  const onDraggedAppointmentLeaveUnassignColumn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.currentTarget.classList.remove("-on-drag-hover");
  };

  if (!selectedLocation) return null;

  if (!selectedLocation.is_dayplanner_enabled)
    return <div className="dayplanner-disabled">{t("v8_dayplanner_not_enabled_for_selected_location").message || "Dayplanner is not enabled for this location."}</div>;

  if (error) return <div>Error {error.message}</div>;

  return (
    <DayplannerContext.Provider
      value={{
        columns,
        onDragAppointmentStart,
        onDraggedAppointmentEnterMechanicPlaceholder,
        onDraggedAppointmentLeaveMechanicPlaceholder,
        onDropAppointmentToMechanicPlaceholder,
        onDraggedAppointmentEnterAnotherAppointment
      }}
    >
      <NavPortal>
        <div className="nav-bar-dp">
          <StatusFilters appointments={preparedAppointments} activeFilters={activeFilters} onSelect={handleSelectStatusFilter} />
        </div>
        <NavBarSearch>
          <NavBarSearchAppointments />
        </NavBarSearch>
      </NavPortal>
      <div className="Dayplanner">
        <div className="Dayplanner-board">
          <div className={`Dayplanner-columnContainer ${collapsedColumns.includes(DAYPLANNER_COLUMNS.Appointments) ? "-collapsed" : ""}`} ref={appointmentsColumnRef}>
            <div className="Dayplanner-columnHeader">
              <h4>
                {collapsedColumns.includes(DAYPLANNER_COLUMNS.Appointments) ? t("v8_appointments_short").message || "AP" : t("v8_appointments").message || "Appointments"}
              </h4>

              <Label onClick={() => handleToggleCollapseColumn(DAYPLANNER_COLUMNS.Appointments)}>
                <Icon className={collapsedColumns.includes(DAYPLANNER_COLUMNS.Appointments) ? "arrows maximize" : "arrows minimize"} color="green" />
              </Label>
            </div>

            <div className="Dayplanner-column">
              {!loading &&
                didApplyFilters &&
                columns.appointmentsColumn.map((app: Appointment) => (
                  <AppointmentCard appointment={app} column={DAYPLANNER_COLUMNS.Appointments} key={app.id} collapsedColumns={collapsedColumns} />
                ))}
            </div>
          </div>

          <div className="Dayplanner-columnContainer" ref={unassignedColumnRef}>
            <div className="Dayplanner-columnHeader">
              <h4>{t("v8_unassigned").message || "Unassigned"}</h4>
            </div>

            <div
              className="Dayplanner-column"
              onDrop={onDropAppointmentToUnassignColumn}
              onDragEnter={onDraggedAppointmentEnterUnassignColumn}
              onDragLeave={onDraggedAppointmentLeaveUnassignColumn}
              onDragOver={e => e.preventDefault()}
            >
              {!loading &&
                didApplyFilters &&
                columns.unassignedColumn.map((app: Appointment) => <AppointmentCard appointment={app} column={DAYPLANNER_COLUMNS.Unassigned} key={app.id} />)}
            </div>
          </div>

          <div className="Dayplanner-columnContainer -mechanic-tasks -flex-grow-3" ref={mechanicsColumnRef}>
            <div className="Dayplanner-columnHeader">
              <h4>{t("v8_mechanics_tasks").message || "Mechanic's Tasks"}</h4>
              <div className="Dayplanner-columnHeader-section">
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon={"filter"}
                  options={[
                    { key: 1, value: WO_INTERNAL_FILTER.All, text: t("v8_all").message || "All" },
                    { key: 2, value: WO_INTERNAL_FILTER.WO, text: t("v8_wo").message || "WO" },
                    { key: 3, value: WO_INTERNAL_FILTER.Internal, text: t("v8_internal").message || "Internal" }
                  ]}
                  value={selectedWoInternalFilter}
                  onChange={handleWoInternalFilter}
                />
                <Dropdown
                  button
                  className="icon"
                  floating
                  labeled
                  icon={"filter"}
                  options={[
                    { key: 1, value: MECHANIC_FILTERS.All, text: t("v8_all").message || "All" },
                    { key: 2, value: MECHANIC_FILTERS.HideOccupiedMechanics, text: t("v8_hide_occupied_mechanics").message || "Hide occupied mechanics" },
                    { key: 3, value: MECHANIC_FILTERS.HideUnoccupiedMechanics, text: t("v8_hide_unoccupied_mechanics").message || "Hide unoccupied mechanics" }
                  ]}
                  value={mechanicsFilter}
                  onChange={handleSelectMechanicFilter}
                />
                <Dropdown
                  button
                  floating
                  className="icon sort-btn"
                  trigger={<img className="asc-desc-img" src={mechanicsSorting === MECHANIC_SORTING.Ascending ? AscImg : DescImg} />}
                  options={[
                    { key: 1, value: MECHANIC_SORTING.Ascending, icon: <img className="asc-desc-img" src={AscImg} /> },
                    { key: 2, value: MECHANIC_SORTING.Descending, icon: <img className="asc-desc-img" src={DescImg} /> }
                  ]}
                  icon={null}
                  value={mechanicsSorting}
                  onChange={handleSelectMechanicSorting}
                />
              </div>
            </div>

            <div className="Dayplanner-column -mechanic-tasks">
              {!loading &&
                didApplyFilters &&
                columns.mechanicsColumn.map((mechanic: MechanicWithAppointments) => {
                  if (
                    (mechanicsFilter === MECHANIC_FILTERS.HideOccupiedMechanics && mechanic.appointments.length > 0) ||
                    (mechanicsFilter === MECHANIC_FILTERS.HideUnoccupiedMechanics && mechanic.appointments.length === 0)
                  )
                    return null;

                  return (
                    <div key={mechanic.id} className="MechanicBox-wrapper">
                      <MechanicBox
                        activeFilters={activeFilters}
                        searchTerm={searchTerm}
                        mechanic={mechanic}
                        key={mechanic.id}
                        mechanicsPinned={mechanicsPinned}
                        onToggleMechanicPin={handleToggleMechanicPin}
                        numberOfCardShownInMechanicColumn={numberOfCardShownInMechanicColumn}
                        onToggleExpand={handleToggleExpandMechanic}
                        expanded={expandedMechanics.has(mechanic.id) || !!mechanic.auto_expand}
                      />
                    </div>
                  );
                })}
            </div>
          </div>

          <div className={`Dayplanner-columnContainer ${collapsedColumns.includes(DAYPLANNER_COLUMNS.CarReady) ? "-collapsed" : ""}`} ref={carReadyColumnRef}>
            <div className="Dayplanner-columnHeader">
              <h4>{collapsedColumns.includes(DAYPLANNER_COLUMNS.CarReady) ? t("v8_cars_ready_short").message || "CR" : t("v8_cars_ready").message || "Cars Ready"}</h4>

              <Label onClick={() => handleToggleCollapseColumn(DAYPLANNER_COLUMNS.CarReady)}>
                <Icon className={collapsedColumns.includes(DAYPLANNER_COLUMNS.CarReady) ? "arrows maximize" : "arrows minimize"} color="green" />
              </Label>
            </div>

            <div className="Dayplanner-column">
              {!loading &&
                didApplyFilters &&
                columns.carReadyColumn.map((app: Appointment) => (
                  <AppointmentCard appointment={app} column={DAYPLANNER_COLUMNS.CarReady} key={app.id} collapsedColumns={collapsedColumns} />
                ))}
            </div>
          </div>

          <div className={`Dayplanner-columnContainer ${collapsedColumns.includes(DAYPLANNER_COLUMNS.QualityCheck) ? "-collapsed" : ""}`} ref={qualityControlColumnRef}>
            <div className="Dayplanner-columnHeader">
              <h4>
                {collapsedColumns.includes(DAYPLANNER_COLUMNS.QualityCheck)
                  ? t("v8_quality_control_short").message || "QC"
                  : t("v8_quality_control").message || "Quality Control"}
              </h4>

              <Label onClick={() => handleToggleCollapseColumn(DAYPLANNER_COLUMNS.QualityCheck)}>
                <Icon className={collapsedColumns.includes(DAYPLANNER_COLUMNS.QualityCheck) ? "arrows maximize" : "arrows minimize"} color="green" />
              </Label>
            </div>

            <div className="Dayplanner-column">
              {!loading &&
                didApplyFilters &&
                columns.qualityControlColumn.map((app: Appointment) => (
                  <AppointmentCard appointment={app} column={DAYPLANNER_COLUMNS.QualityCheck} key={app.id} collapsedColumns={collapsedColumns} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </DayplannerContext.Provider>
  );
};

export default withTranslation()(Dayplanner);
