import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Auth as AuthHook, Can, ErrorFallback, PrivateRoute, PublicRoute } from "components";
import { useApp } from "hooks";
import { PrivateRouteLayout, PublicRouteLayout } from "layouts";
import { AppointmentDetails, Appointments, Auth, CarDetails, CustomerCom, Dayplanner, DealerDocumentations, Styleguide } from "modules";
import AccountSettings from "modules/Auth/components/AccountSettings";
import { PATHS } from "router/paths";

// eslint-disable-next-line no-relative-import-paths/no-relative-import-paths, import/order
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  useApp();

  return (
    <>
      <ToastContainer position="top-right" autoClose={1500} closeOnClick draggable theme="light" />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
          <AuthHook />
          <Switch>
            <Route exact path={[PATHS.HOME, PATHS.LOGIN, PATHS.RESET_PASSWORD, PATHS.RESET_PASSWORD_REQUEST]}>
              <PublicRouteLayout>
                <Switch>
                  <PublicRoute exact path={PATHS.HOME} component={Auth} />
                  <PublicRoute exact path={PATHS.LOGIN} component={Auth} />
                  <PublicRoute exact path={PATHS.RESET_PASSWORD} component={Auth} />
                  <PublicRoute exact path={PATHS.RESET_PASSWORD_REQUEST} component={Auth} />
                </Switch>
              </PublicRouteLayout>
            </Route>

            <Route>
              <PrivateRouteLayout>
                <Switch>
                  <PrivateRoute
                    exact
                    path={PATHS.APPOINTMENTS}
                    render={() => (
                      <Can I="browse" the="appointments-page" renderNoAccess>
                        <Appointments />
                      </Can>
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={PATHS.APPOINTMENT_DETAIL}
                    render={() => (
                      <Can I="browse" the="appointments-details-page" renderNoAccess>
                        <AppointmentDetails />
                      </Can>
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={PATHS.DAY_PLANNER}
                    render={() => (
                      <Can I="browse" the="dayplanner-page" renderNoAccess>
                        <Dayplanner />
                      </Can>
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={PATHS.CAR_DETAILS}
                    render={() => (
                      <Can I="browse" the="appointments-car-page" renderNoAccess>
                        <CarDetails />
                      </Can>
                    )}
                  />
                  <PrivateRoute exact path={PATHS.STYLE_GUIDE} component={Styleguide} />
                  <PrivateRoute
                    exact
                    path={PATHS.CUSTOMER_COM}
                    render={() => (
                      <Can I="browse" the="customer-comm-settings-page" renderNoAccess>
                        <CustomerCom />
                      </Can>
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={PATHS.ACCOUNT}
                    render={() => (
                      <Can I="browse" the="account-page" renderNoAccess>
                        <AccountSettings />
                      </Can>
                    )}
                  />
                  <PrivateRoute
                    exact
                    path={PATHS.DEALER_DOCUMENTATION}
                    render={() => (
                      <Can I="browse" the="dealer-documentation-page" renderNoAccess>
                        <DealerDocumentations />
                      </Can>
                    )}
                  />
                </Switch>
              </PrivateRouteLayout>
            </Route>
          </Switch>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </ErrorBoundary>
    </>
  );
};

export default App;
