import { QueryKey, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import { WebSocketMessageListener } from "components";
import ENV from "config/Env";
import { useRealTimeQuery, useUser } from "hooks";
import { Appointment, Check, QuestionResult, ROLES, STATUS_IDENTIFIER, SnoozeItem } from "models";
import ApiInstance from "util/Api";
import { BackendQueryKey, queryKeys } from "util/keyFactory";

export const useChecklist = (appointment_id: number, subscribeAndInvalidate: boolean = false) => {
  const user = useUser();
  const queryClient = useQueryClient();

  const appointmentQueryKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.view,
      params: { ...(queryKeys.appointmentDetails.view as BackendQueryKey).params, id: String(appointment_id) }
    }
  ];

  const { data: appointmentData } = useQuery({
    queryKey: appointmentQueryKey
  });

  const fetchChecklist = async ({ queryKey }: { queryKey: QueryKey }) => {
    const { endpoint, params } = queryKey[1] as BackendQueryKey;
    const res = await ApiInstance.post(endpoint, { ...params, appointment_id: params?.appointment_id });
    return res.data.checks;
  };

  const checklistQueryKey = [
    "realtime",
    {
      ...queryKeys.appointmentDetails.checklist,
      params: { appointment_id, embed: "pin,snooze" }
    }
  ];

  const listeners: WebSocketMessageListener[] = [
    {
      model: "Check",
      action: "update",
      filter: {
        appointment_id: appointment_id
      },
      callback: message => {
        const checkSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);
        const checkData = message.data as Check;

        let updatedCheckList = [checkData];
        if (checkSnapshot?.length) {
          let found = false;
          const updatedChecks = [...checkSnapshot].map(check => {
            if (check.id === checkData.id) {
              found = true;
              return {
                ...check,
                ...checkData
              } as Check;
            }
            return check;
          });
          if (found) {
            updatedCheckList = [...updatedChecks];
          } else {
            updatedCheckList = [...checkSnapshot].concat(checkData);
          }
        }
        queryClient.setQueryData(checklistQueryKey, updatedCheckList);
      }
    },
    {
      model: "QuestionResult",
      action: "update",
      filter: {
        appointment_id: appointment_id
      },
      callback: message => {
        const questionResult = message.data as QuestionResult;
        const checkSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);

        if (checkSnapshot?.length) {
          const clonedCheck: Check[] = [...checkSnapshot];
          const targetCheck = clonedCheck.find(check => check.id === questionResult.check_id);

          if (targetCheck?.question_items) {
            const clonedQuestionItems = [...targetCheck.question_items];
            const targetQuestionResult = clonedQuestionItems.find(item => item.id === questionResult.id);

            if (targetQuestionResult) {
              Object.assign(targetQuestionResult, questionResult);
              queryClient.setQueryData(checklistQueryKey, clonedCheck);
            }
          }
        }

        const appointmentData: Appointment | undefined = queryClient.getQueryData(appointmentQueryKey);
        if (!appointmentData?.interventions) {
          return;
        }
        let foundQuestionResultID = false;
        const updatedInterventions = appointmentData.interventions.map(intervention => {
          if (intervention.question_result_id === questionResult.id) {
            foundQuestionResultID = true;
            return { ...intervention, question_result_status: questionResult.status };
          }
          return intervention;
        });
        if (foundQuestionResultID) {
          const updatedAppointment = { ...appointmentData, interventions: updatedInterventions };
          queryClient.setQueryData(appointmentQueryKey, updatedAppointment);
        }
      }
    },
    {
      model: "Snooze",
      action: "append",
      filter: {
        appointment_id: appointment_id
      },
      callback: message => {
        const checkSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);

        if (checkSnapshot?.length) {
          const clonedCheck = checkSnapshot.map(check => {
            if (check.id === (message.data as QuestionResult).check_id) {
              const clonedQuestionItems = check?.question_items?.map(item => {
                if (item.id === (message.data as any).question_result_id) {
                  const newItem = {
                    ...item,
                    snooze_history: item.snooze_history ? item.snooze_history.concat(message.data as SnoozeItem) : [message.data as SnoozeItem],
                    snoozed: true
                  };

                  return newItem;
                }
                return item;
              });

              return {
                ...check,
                question_items: clonedQuestionItems
              };
            }
            return check;
          });

          queryClient.setQueryData(checklistQueryKey, clonedCheck);
        }
      }
    },
    {
      model: "Snooze",
      action: "delete",
      filter: {
        appointment_id: appointment_id
      },
      callback: message => {
        const checkSnapshot: Check[] | undefined = queryClient.getQueryData(checklistQueryKey);

        if (!checkSnapshot?.length) {
          return;
        }

        const updatedChecks = checkSnapshot.map(check => {
          const updatedQuestionItems = check?.question_items?.map(questionItem => {
            if (questionItem.id === (message.data as any).question_result_id) {
              return {
                ...questionItem,
                snooze_history: [],
                snoozed: false
              };
            }
            return questionItem;
          });

          return {
            ...check,
            question_items: updatedQuestionItems
          };
        });

        queryClient.setQueryData(checklistQueryKey, updatedChecks);
      }
    }
  ];

  const checklistQuery = useRealTimeQuery({
    queryKey: checklistQueryKey,
    queryFn: fetchChecklist,
    listeners: subscribeAndInvalidate ? listeners : []
  });

  const clearCheckData = () => {
    if (subscribeAndInvalidate) {
      queryClient.removeQueries({ queryKey: checklistQueryKey });
    }
  };

  useEffect(() => {
    return () => clearCheckData();
  }, []);

  const updateChecklist = ({ data }: { data: QuestionResult }) => {
    const checklistSnapshot = queryClient.getQueryData(checklistQueryKey);

    const checklist = [...(checklistQuery?.data || [])];

    const parentCheckIndex = checklist.findIndex(item => item.id === data.check_id);

    if (parentCheckIndex !== -1 && checklist[parentCheckIndex]?.question_items) {
      const questionItemIndex = checklist[parentCheckIndex].question_items.findIndex((item: Check) => item.id === data.id);

      if (questionItemIndex !== -1) {
        checklist[parentCheckIndex].question_items[questionItemIndex] = { ...checklist[parentCheckIndex].question_items[questionItemIndex], ...data };
        queryClient.setQueryData(checklistQueryKey, checklist);
      }
    }

    return { checklistSnapshot, checklistQueryKey };
  };

  const deleteCheckListFn = async (id: number) => {
    const res = await ApiInstance.post("/checks/delete_last_check", { id });
    return res.data;
  };

  const deleteCheckList = useMutation({
    mutationFn: deleteCheckListFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: checklistQueryKey });
    },
    onError: e => {
      toast.error(e.message);
    }
  });

  const updateCheckQuestionItemFn = async ({ data }: { data: Partial<QuestionResult> }) => {
    const queryData = {
      id: data.id,
      dealer_location_id: data.dealer_location_id,
      appointment_id: data.appointment_id,
      status: data.status,
      images_update: data.images,
      videos_update: data.video,
      raw: data.raw,
      wo: (appointmentData as Appointment).wo_nr,
      reg: (appointmentData as Appointment).reg_number
    };
    await ApiInstance.post("/checks/update_question_item", queryData);
    return data;
  };

  const updateCheckQuestionItem = useMutation({
    mutationFn: updateCheckQuestionItemFn,
    onMutate: updateChecklist,
    onError: (e, _variables, context) => {
      toast.error(e.message);
      if (context?.checklistSnapshot && context?.checklistQueryKey) {
        queryClient.setQueryData(context.checklistQueryKey, context.checklistSnapshot);
      }
    }
  });

  const canDeleteCheck = useMemo(() => {
    if ((appointmentData as Appointment)?.appointment_status_identifier === STATUS_IDENTIFIER.CarCheckStatus) {
      if (user?.role_id) {
        switch (user?.role_id) {
          case ROLES.DealerAdmin:
          case ROLES.SuperAdmin:
          case ROLES.ClaireAdmin:
          case ROLES.SupportAdmin:
            return true;
          default:
            return false;
        }
      }
    }
    return false;
  }, [user?.role_id, (appointmentData as Appointment)?.appointment_status_identifier]);

  const updateQuestionItemRemarkFn = async ({ id, remark }: { id: number; remark: string }) => {
    const response = await ApiInstance.post("/checks/update_question_result_remark", { question_result_id: id, remark }, ENV.appointmentBaseURL);
    return response;
  };

  const updateQuestionItemRemark = useMutation({
    mutationFn: updateQuestionItemRemarkFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: checklistQueryKey });
    }
  });

  return { checklistQuery, deleteCheckList, updateCheckQuestionItem, updateQuestionItemRemark, canDeleteCheck };
};
