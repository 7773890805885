import { useTranslation } from "react-i18next";

import { useDealersLocations } from "hooks";
import { KeylockerCommunication } from "models";
import { Agreements, CommunicationEvents, Services } from "modules/AppointmentDetails/components/KeylockerCommunicationLog/components";
import { Appointment } from "modules/Dayplanner/utils";
import { ITranslation } from "util/interfaces";

type ContentProps = {
  appointment: Appointment;
  comm: KeylockerCommunication;
};

export const Content = ({ comm }: ContentProps) => {
  const t = useTranslation().t as ITranslation;
  const { selectedLocation } = useDealersLocations();

  const { events, agreed_interventions, agreements } = comm;

  return (
    <>
      <CommunicationEvents comm={comm} events={events} />
      <Services services={agreed_interventions} title={t("v8_agreed_interventions").message || "Agreed interventions"} vat={selectedLocation?.vat} />
      <Agreements agreements={agreements} />
    </>
  );
};
