import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CUSTOM_CONFIRM_TYPES, CustomConfirm, useCan } from "components";
import { useDealersLocations } from "hooks";
import { Appointment, CHECKLIST_TYPE, STATUS_IDENTIFIER } from "models";
import { StatusBarIcon } from "modules/AppointmentDetails/components";
import "modules/AppointmentDetails/components/AppointmentStatus/AppointmentStatus.scss";
import { useAppointmentData, useKiosk, useUpdateAppointment } from "modules/AppointmentDetails/hooks";
import { isCarReady } from "modules/AppointmentDetails/utils";
import { ITranslation } from "util/interfaces";

export type StatusData = {
  new_status_identifier?: number;
  car_in_shop?: string | null;
  car_out_of_shop?: string | null;
};

type AppointmentStatusProps = {
  appointment: Appointment | null;
  onClick: (statusData: StatusData) => void;
  isUpdating?: boolean;
};

type StatusIconProps = {
  status: STATUS_IDENTIFIER;
  active?: boolean;
  name?: string;
  timestamp?: string | null;
  disabled?: boolean;
  color?: string;
  isMechanicState?: boolean;
};

const getAppointmentStatusNameAndTime = (status: STATUS_IDENTIFIER, appointment: Appointment | null) => {
  const app = appointment?.status_history?.findLast(app => status === app.identifier);

  return { name: app?.last_user || "", timestamp: app?.timestamp || "" };
};

const getIntermittentStatus = (appointment: Appointment | null) => {
  if (!appointment) return null;

  const { name, timestamp } = getAppointmentStatusNameAndTime(appointment.appointment_status_identifier, appointment);

  let statusIconProps: StatusIconProps = {
    name,
    timestamp,
    disabled: true,
    status: STATUS_IDENTIFIER.CarCheckStatus
  };

  switch (appointment.appointment_status_identifier) {
    case STATUS_IDENTIFIER.NewCarStatus:
      statusIconProps = { ...statusIconProps, status: STATUS_IDENTIFIER.CarCheckStatus, name: "", timestamp: null, isMechanicState: true };
      break;
    case STATUS_IDENTIFIER.CarCheckStartedStatus: {
      const lastCheckStartedStatus = appointment.status_history?.findLast(status => status.identifier === STATUS_IDENTIFIER.CarCheckStartedStatus);
      if (lastCheckStartedStatus?.checklist_type !== CHECKLIST_TYPE.QualityChecklist) {
        statusIconProps = { ...statusIconProps, status: STATUS_IDENTIFIER.CarCheckStartedStatus, active: true };
      }
      break;
    }
    case STATUS_IDENTIFIER.CarCheckStatus:
      statusIconProps = { ...statusIconProps, status: STATUS_IDENTIFIER.CarCheckStatus, active: true };
      break;
    case STATUS_IDENTIFIER.AannameOKStatus:
      statusIconProps = { ...statusIconProps, status: STATUS_IDENTIFIER.AannameOKStatus, active: true };
      break;
    case STATUS_IDENTIFIER.DiagnoseStatus:
      statusIconProps = { ...statusIconProps, status: STATUS_IDENTIFIER.DiagnoseStatus, active: true };
      break;
    default: {
      const lastStatus = appointment.status_history?.findLast(
        status =>
          [STATUS_IDENTIFIER.CarCheckStartedStatus, STATUS_IDENTIFIER.CarCheckStatus, STATUS_IDENTIFIER.AannameOKStatus, STATUS_IDENTIFIER.DiagnoseStatus].includes(
            status.identifier
          ) && status.checklist_type !== CHECKLIST_TYPE.QualityChecklist
      );
      if (lastStatus)
        statusIconProps = {
          ...statusIconProps,
          status: lastStatus.identifier,
          name: lastStatus.last_user,
          timestamp: lastStatus.timestamp,
          isMechanicState: true,
          active: false
        };
      else {
        statusIconProps = {
          ...statusIconProps,
          name: "",
          timestamp: null
        };
      }
    }
  }

  return <StatusBarIcon {...statusIconProps} />;
};

const getQualityCheckStatus = (appointment: Appointment | null) => {
  if (!appointment) return null;

  const lastQualityCheckStatus = appointment.status_history?.findLast(status => status.checklist_type === CHECKLIST_TYPE.QualityChecklist);

  if (appointment?.appointment_status_identifier === STATUS_IDENTIFIER.QualityCheckStatus) {
    const { name, timestamp } = getAppointmentStatusNameAndTime(appointment.appointment_status_identifier, appointment);

    return <StatusBarIcon status={STATUS_IDENTIFIER.QualityCheckStatus} name={name} timestamp={timestamp} active disabled />;
  }

  if (appointment?.appointment_status_identifier === STATUS_IDENTIFIER.CarCheckStartedStatus && lastQualityCheckStatus) {
    return (
      <StatusBarIcon status={lastQualityCheckStatus.identifier} name={lastQualityCheckStatus.last_user} timestamp={lastQualityCheckStatus.timestamp} active disabled />
    );
  }
};

export const AppointmentStatus = ({ appointment, onClick, isUpdating }: AppointmentStatusProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showDetachKioskModel, setShowDetachKioskModel] = useState(false);
  const appointmentStatus: STATUS_IDENTIFIER | undefined = appointment?.appointment_status_identifier;
  const { selectedLocation } = useDealersLocations();
  const { restore } = useAppointmentData(String(appointment?.id));
  const { appointmentCarInShopMutation, appointmentCarOutOfShopMutation } = useUpdateAppointment(Number(appointment?.id));
  const { detachKioskMutation } = useKiosk(Number(appointment?.id));
  const canRestoreAppointments = useCan("restore", "appointments");
  const carInShopStatus = appointment?.car_in_shop !== null && appointment?.car_out_of_shop === null;
  const carInShopDisabled =
    appointmentStatus === STATUS_IDENTIFIER.NewCarStatus ? !useCan("update-car-in-shop", "appointments") : !useCan("update-car-out-of-shop", "appointments");
  const t = useTranslation().t as ITranslation;

  const toggleConfirmModal = () => {
    setShowConfirmModal(prev => !prev);
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClick({ new_status_identifier: STATUS_IDENTIFIER.CarReadyStatus });
    toggleConfirmModal();
  };

  const isActive = (appointmentStatusId: STATUS_IDENTIFIER | undefined, statusId: number) => {
    return appointmentStatusId === statusId;
  };

  const handleDetachKiosk = () => {
    detachKioskMutation.mutate();
    setShowDetachKioskModel(false);
  };

  if (appointmentStatus === STATUS_IDENTIFIER.CanceledStatus && !canRestoreAppointments) return null;

  const carInShopName = () => {
    if (carInShopStatus)
      return appointment.car_in_shop_set_by
        ? `${appointment.car_in_shop_set_by.first_name} ${appointment.car_in_shop_set_by.last_name}`.trim()
        : appointment.customer_owner_name || "";
    else return (appointment?.car_out_of_shop_set_by && `${appointment.car_out_of_shop_set_by.first_name} ${appointment.car_out_of_shop_set_by.last_name}`.trim()) || "";
  };

  const onClickHandler = () => {
    if (isUpdating) return;

    if (appointmentStatus === STATUS_IDENTIFIER.CanceledStatus) restore.mutate();
    else {
      const timeStamp = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

      if (carInShopStatus) {
        appointmentCarOutOfShopMutation.mutate({ car_out_of_shop: timeStamp });
        if (appointment?.kiosk_label_number) setShowDetachKioskModel(true);
      } else {
        appointmentCarInShopMutation.mutate({ car_in_shop: timeStamp, car_out_of_shop: null });
      }
    }
  };

  return (
    <div className="AppointmentStatus">
      <StatusBarIcon
        name={carInShopName()}
        timestamp={carInShopStatus ? appointment?.car_in_shop : appointment?.car_out_of_shop}
        status={carInShopStatus ? STATUS_IDENTIFIER.CarInShop : STATUS_IDENTIFIER.CarOutOfShop}
        active={carInShopStatus}
        disabled={carInShopDisabled}
        onClick={onClickHandler}
      />

      {getIntermittentStatus(appointment)}

      <StatusBarIcon
        name={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CalculatingStatus, appointment)?.name}
        timestamp={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CalculatingStatus, appointment)?.timestamp}
        status={STATUS_IDENTIFIER.CalculatingStatus}
        disabled={appointmentStatus !== STATUS_IDENTIFIER.CarCheckStatus}
        active={isActive(appointmentStatus, STATUS_IDENTIFIER.CalculatingStatus)}
        onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.CalculatingStatus })}
      />

      <StatusBarIcon
        name={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.PricingOKStatus, appointment)?.name}
        timestamp={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.PricingOKStatus, appointment)?.timestamp}
        status={STATUS_IDENTIFIER.PricingOKStatus}
        disabled={appointmentStatus !== STATUS_IDENTIFIER.CalculatingStatus}
        active={isActive(appointmentStatus, STATUS_IDENTIFIER.PricingOKStatus)}
        onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.PricingOKStatus })}
      />

      {(selectedLocation?.diagnose_overview_enabled || selectedLocation?.online_check_in_enabled || selectedLocation?.repair_overview_enabled) && (
        <StatusBarIcon
          name={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.ContactStatus, appointment)?.name}
          timestamp={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.ContactStatus, appointment)?.timestamp}
          status={STATUS_IDENTIFIER.ContactStatus}
          customcomStatus={appointment?.customer_communication?.status}
          disabled={appointmentStatus !== STATUS_IDENTIFIER.PricingOKStatus}
          active={isActive(appointmentStatus, STATUS_IDENTIFIER.ContactStatus)}
          onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.ContactStatus })}
        />
      )}

      {selectedLocation?.diagnose_overview_enabled && (
        <StatusBarIcon
          status={STATUS_IDENTIFIER.CustomerAnsweredStatus}
          name={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CustomerAnsweredStatus, appointment)?.name}
          timestamp={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CustomerAnsweredStatus, appointment)?.timestamp}
          disabled
          active={appointmentStatus === STATUS_IDENTIFIER.CustomerAnsweredStatus}
        />
      )}

      {appointmentStatus === STATUS_IDENTIFIER.BackOrderStatus && (
        <StatusBarIcon
          name={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.BackOrderStatus, appointment)?.name}
          timestamp={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.BackOrderStatus, appointment)?.timestamp}
          status={STATUS_IDENTIFIER.BackOrderStatus}
          active={true}
        />
      )}

      <StatusBarIcon
        name={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CustomerOKStatus, appointment)?.name}
        timestamp={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CustomerOKStatus, appointment)?.timestamp}
        status={STATUS_IDENTIFIER.CustomerOKStatus}
        active={isActive(appointmentStatus, STATUS_IDENTIFIER.CustomerOKStatus)}
        disabled={
          appointmentStatus === STATUS_IDENTIFIER.CanceledStatus ||
          (appointmentStatus !== STATUS_IDENTIFIER.ContactStatus &&
            appointmentStatus !== STATUS_IDENTIFIER.PricingOKStatus &&
            appointmentStatus !== STATUS_IDENTIFIER.CustomerAnsweredStatus &&
            appointmentStatus !== STATUS_IDENTIFIER.BackOrderStatus)
        }
        onClick={() => !isUpdating && onClick({ new_status_identifier: STATUS_IDENTIFIER.CustomerOKStatus })}
      />

      {selectedLocation?.car_ready_btn_visible && (
        <StatusBarIcon
          name={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CarReadyStatus, appointment)?.name}
          timestamp={getAppointmentStatusNameAndTime(STATUS_IDENTIFIER.CarReadyStatus, appointment)?.timestamp}
          status={STATUS_IDENTIFIER.CarReadyStatus}
          active={isCarReady(appointmentStatus as STATUS_IDENTIFIER)}
          disabled={
            appointmentStatus === STATUS_IDENTIFIER.CanceledStatus ||
            isUpdating ||
            (appointmentStatus !== STATUS_IDENTIFIER.BackOrderStatus && isCarReady(appointmentStatus as STATUS_IDENTIFIER))
          }
          onClick={toggleConfirmModal}
        />
      )}

      {getQualityCheckStatus(appointment)}

      <div className="horizontal-line" />
      <CustomConfirm
        isLoading={!!isUpdating}
        isOpen={showConfirmModal}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        handleConfirm={handleConfirm}
        handleCancel={toggleConfirmModal}
        confirmMsg={t("v8_are_you_sure_car_ok_status").message || "Are you sure you want to change the status to Car Ready?"}
      />
      <CustomConfirm
        customTitle={t("v8_key_label_warning").message || "Key Label Warning"}
        type={CUSTOM_CONFIRM_TYPES.Warning}
        allowCustomReason
        isOpen={showDetachKioskModel}
        customClass={"CancelAppointmentModal"}
        handleCancel={() => setShowDetachKioskModel(false)}
        handleConfirm={handleDetachKiosk}
        isLoading={detachKioskMutation.isPending}
        confirmMsg={t("v8_confirm_disconnect_key_from_appointment").message || "Do you want to disconnect the key label from this WO?"}
        cancelButtonText={t("v8_no").message || "No"}
        confirmButtonText={t("v8_yes").message || "Yes"}
      />
    </div>
  );
};
