import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";

import { useDealersLocations } from "hooks";
import { CheckInCommunicationResult } from "models";
import { getPriceForDisplay } from "modules/AppointmentDetails/utils";
import { ITranslation } from "util/interfaces";

type CheckinResultProps = {
  results: CheckInCommunicationResult[];
};

export const CheckinResults = ({ results }: CheckinResultProps) => {
  const { selectedLocation } = useDealersLocations();
  const t = useTranslation().t as ITranslation;

  if (!results?.length) return null;

  return (
    <div className="info-section">
      <div className="section-header">{t("v8_extra_services").message || "Extra services"}</div>
      <div className="section-content">
        <Grid>
          <Grid.Column width={16}>
            <div className="services-header">
              <span>{t("v8_title").message || "Title"}</span>
              <span>{selectedLocation?.include_vat ? t("v8_price_inc_vat").message || "Price (incl. VAT)" : t("v8_price_excl_vat").message || "Price (excl. VAT)"}</span>
            </div>
            {results
              .filter(r => r.accepted)
              .map((result, key) => (
                <div className="service" key={key}>
                  <span>{result.name}</span>
                  <span>
                    {selectedLocation?.include_vat ? getPriceForDisplay(result.price * (1 + (selectedLocation?.vat || 0) / 100)) : getPriceForDisplay(result.price)} €
                  </span>
                </div>
              ))}
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
};
