import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { WebSocketComponent, WebSocketMessageListener } from "components";

interface ExtendedUseQueryOptions<TQueryFnData, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>
  extends UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> {
  listeners: WebSocketMessageListener[];
}

export const useRealTimeQuery = <TQueryFnData, TError = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
  options: ExtendedUseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const { listeners, ...restOptions } = options;
  const query = useQuery({ ...restOptions });

  useEffect(() => {
    if (listeners.length < 1 || !query.isFetched) return;

    const removeMessageListeners: (() => void)[] = [];

    listeners.forEach(listener => {
      const removeMessageListener = WebSocketComponent.addMessageListener(listener);
      removeMessageListeners.push(removeMessageListener);
    });

    return () => {
      removeMessageListeners.forEach(removeMessageListener => removeMessageListener());
    };
  }, [listeners, query.isFetched]); // TODO this is trigered every time parents are rendering, as it's a new array ref every time... need a static ref

  return query;
};
